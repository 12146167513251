<template>
  <div>
    <div class="studentlist">
      <div class="
          operationControl
          flexdc
          operationControlCurr
          operationControlWidth
        " style="align-items: flex-start">
        <div class="searchbox" style="margin-bottom: 10px">
          <div title="姓名" class="searchboxItem ci-full">
            <span class="itemLabel" style="min-width: 7rem">姓名:</span>
            <el-input v-model="userName" type="text" size="small" placeholder="请输入" clearable />
          </div>
          <div title="身份证号" class="searchboxItem ci-full">
            <span class="itemLabel" style="min-width: 7rem">身份证号:</span>
            <el-input v-model="idcard" type="text" size="small" placeholder="请输入" clearable />
          </div>
          <div title="手机号" class="searchboxItem ci-full">
            <span class="itemLabel" style="min-width: 7rem">手机号:</span>
            <el-input v-model="mobile" type="text" size="small" placeholder="请输入" clearable />
          </div>
          <div title="是否结业" class="searchboxItem ci-full">
            <span class="itemLabel" style="min-width: 7rem">是否结业:</span>
            <el-select v-model="graduationState" placeholder="请选择" clearable size="small">
              <el-option label="是" :value="true"></el-option>
              <el-option label="否" :value="false"></el-option>
            </el-select>
          </div>
        </div>
        <div class="searchbox" style="margin-bottom: 10px">
          <div title="实名认证" class="searchboxItem ci-full">
            <span class="itemLabel" style="min-width: 7rem">实名认证:</span>
            <el-select v-model="authenticationState" placeholder="请选择" clearable size="small">
              <el-option v-for="item in authenticationList" :key="item.value" :label="item.label"
                :value="item.value"></el-option>
            </el-select>
          </div>
          <div title="学时学习状态" class="searchboxItem ci-full">
            <span class="itemLabel" style="min-width: 7rem">学时学习状态:</span>
            <el-select v-model="completeState" placeholder="请选择" clearable size="small">
              <el-option v-for="item in complete" :key="item.value" :label="item.label" :value="item.value"></el-option>
            </el-select>
          </div>
          <div title="入班时间" class="searchboxItem ci-full">
            <span class="itemLabel" style="min-width: 7rem">入班时间:</span>
            <el-date-picker size="small" v-model="date" type="daterange" range-separator="至" start-placeholder="开始日期"
              end-placeholder="结束日期" format="yyyy-MM-dd" value-format="yyyy-MM-dd"></el-date-picker>
          </div>
        </div>
        <div class="searchbox" style="margin-bottom: 10px">
          <div title="有无证件照片" class="searchboxItem ci-full">
            <span class="itemLabel" style="min-width: 7rem">有无证件照片:</span>
            <el-select size="small" v-model="hasIdPhoto" filterable clearable placeholder="请选择有无证件照片">
              <el-option v-for="item in hasIdPhotoSelect" :key="item.value" :label="item.label"
                :value="item.value"></el-option>
            </el-select>
          </div>
          <div>
            <el-button type="primary" class="bgc-bv" round @click="getData()">查询</el-button>
            <el-button type="primary" size="mini" class="bgc-bv" round @click="handleAdd('add')" v-if="(projectSource != '30' && projectSource != '60') ||
              (projectSource == '30' &&
                (auditState == '10' ||
                  auditState == '40' ||
                  auditState == '45'))
              ">新增学员</el-button>
            <el-button type="primary" class="bgc-bv" round @click="Exportstudent" v-if="(projectSource != '30' && projectSource != '60') ||
              (projectSource == '30' &&
                (auditState == '10' ||
                  auditState == '40' ||
                  auditState == '45'))
              ">导入学员</el-button>
            <el-button class="bgc-bv" round @click="Exportstudent2" v-if="Exportstudent2Button">导入报名学员</el-button>
            <el-button class="bgc-bv" round @click="exportNum">导出档案及证书编号</el-button>
            <el-button class="bgc-bv" round @click="exportClassStudent">导出班级学员</el-button>
            <el-button type="primary" class="bgc-bv" round @click="PersonnelCategory()" v-show="zF.getString2(areaId, 2) == 22 || zF.getString2(areaId, 2) == 23
              ">人员类别维护</el-button>
            <el-button class="bgc-bv" round @click="plsczjzp">导入二寸照片</el-button>
          </div>
        </div>
      </div>
    </div>

    <div class="framePage-scroll">
      <div class="ovy-a">
        <el-table ref="multipleTable" :data="tableData" :height="tableHeight" size="small" tooltip-effect="dark"
          style="width: 100%" :header-cell-style="tableHeader" stripe>
          <el-table-column label="序号" align="center" type="index" :index="indexMethod" fixed />
          <el-table-column label="姓名" align="center" show-overflow-tooltip prop="userName" />
          <el-table-column label="证件类型" align="center" show-overflow-tooltip prop="idType">
            <template slot-scope="scope">
              {{ $setDictionary("EDU_USER_CARD_TYPE", scope.row.idType) }}
            </template>
          </el-table-column>
          <el-table-column label="证件号码" align="center" show-overflow-tooltip prop="idcard" width="160" />
          <el-table-column label="手机号" align="center" show-overflow-tooltip prop="mobile" width="100" />
          <el-table-column label="实名认证" align="center" show-overflow-tooltip>
            <template slot-scope="scope">
              <span>
                {{ $setDictionary("AUTHENTICATION", scope.row.authentication) }}
              </span>
            </template>
          </el-table-column>
          <el-table-column label="课件总数" align="center" show-overflow-tooltip>
            <template slot-scope="scope">
              <span>{{ scope.row.kpointTotalNum }}</span>
            </template>
          </el-table-column>
          <el-table-column label="已完成课件数" align="center" width="100">
            <template slot-scope="scope">
              <span>{{ scope.row.completeKpointNum }}</span>
            </template>
          </el-table-column>
          <el-table-column label="学时学习状态" align="center" minWidth="120">
            <template slot-scope="scope">
              <span v-if="scope.row.studyState != '40'">
                {{ $setDictionary("LEARNINGSTATE", scope.row.studyState) }}
              </span>
              <el-tooltip v-else class="item" effect="dark" :content="scope.row.notCompleteReasons" placement="top">
                <span style="
                    cursor: pointer;
                    display: flex;
                    justify-content: center;
                  ">{{
                    $setDictionary("LEARNINGSTATE", scope.row.studyState)
                  }}</span>
              </el-tooltip>
            </template>
          </el-table-column>
          <el-table-column label="操作人" align="center" show-overflow-tooltip prop="fullname" />
          <el-table-column label="有无证件照片" align="center" show-overflow-tooltip prop="hasIdPhoto" width="160">
            <template slot-scope="scope">
              {{ scope.row.hasIdPhoto ? "有" : "无" }}
            </template>
          </el-table-column>
          <el-table-column label="结业状态" align="center" show-overflow-tooltip prop="graduationState" width="160">
            <template slot-scope="scope">
              {{ scope.row.graduationState ? "已结业" : "未结业" }}
            </template>
          </el-table-column>
          <el-table-column label="证件照片" width="120" align="center" show-overflow-tooltip prop="idPhotoUrl">
            <template slot-scope="scope">
              <el-image style=" 
                  height: 50px;
                  display: flex;
                  align-items: center;
                  justify-content: center;
                " :src="scope.row.idPhotoUrl">
                <div slot="error" class="image-slot">--</div>
              </el-image>
            </template>
          </el-table-column>
          <el-table-column label="入班时间" align="center" show-overflow-tooltip prop="createTime" width="200">
            <template slot-scope="scope">
              {{ scope.row.createTime | moment }}
            </template>
          </el-table-column>
          <el-table-column label="操作" align="center" :width="trainTypeId == 11 ? '380px' : '380px'" fixed="right">
            <div slot-scope="scope" class="flexcc">
              <el-button type="text" size="mini" style="padding: 0 5px" @click="
                lookstudent(
                  scope.row.projectUserCourseId,
                  scope.row.authentication,
                  'edit'
                )
                ">查看</el-button>
              <el-button type="text" size="mini" style="padding: 0 5px" v-if="projectSource != '60'" :disabled="scope.row.eligible === 0 ||//如档案标记不合格的，不能从班级移除
                scope.row.studyState == '30' ||
                (projectSource == '30' &&
                  auditState != '10' &&
                  auditState != '40' &&
                  auditState != '45')
                " @click="handleDel(scope.row.userId)">从本班移除</el-button>
              <!-- <el-button
                v-if="scope.row.auditState !== '30'"
                type="text"
                size="mini"
                style="padding: 0 5px"
                @click="Learningrecords(scope.row)"
                >学习记录</el-button
              > -->
              <el-button type="text" size="mini" style="padding: 0 5px" :disabled="!scope.row.canPrint" @click="
                downloadTrainDocument(scope.row.projectId, scope.row.userId)
                ">下载培训档案</el-button>
              <el-button type="text" size="mini" style="padding: 0 5px" :disabled="!scope.row.canPrint" @click="
                downloadTrainCert(scope.row.projectId, scope.row.userId)
                ">下载学时证明</el-button>
              <!-- <el-button
                type="text"
                size="mini"
                style="padding: 0 5px"
                v-show="trainTypeId == 11"
                :disabled="!scope.row.canPrint"
                @click="showDocsDialog(scope.row)"
                >下载全员培训档案</el-button
              > -->
            </div>
          </el-table-column>
          <Empty slot="empty" />
        </el-table>
      </div>
    </div>
    <PageNum :apiData="apiData" @sizeChange="sizeChange" @getData="getData" />
    <el-dialog :title="dialogTitle" :visible.sync="dialogVisible" width="55%" top="1%" :center="true"
      :before-close="doClose" :close-on-click-modal="false">
      <div class="ovy-a" style="height: 500px">
        <el-form label-width="120px" ref="Form" :model="Form" :rules="rules">
          <el-form-item label="证件类型" prop="idType">
            <el-select v-model="Form.idType" disabled placeholder="请选择证件类型" size="small" clearable>
              <el-option v-for="item in EduUserCardList" :key="item.value" :label="item.label" :value="item.value">
              </el-option>
            </el-select>
          </el-form-item>
          <el-form-item label="证件号码" prop="idCard" :rules="{
            required: true,
            validator: Form.idType == '10' ? this.$validateIdCard : '',
            message:
              Form.idType == '10' ? '请输入正确的证件号码' : '请输入证件号码',
            trigger: 'blur',
          }">
            <el-input :disabled="disabledcard" v-model="Form.idCard" type="text" size="small" @input="(val) => {
              getIdcard(val);
            }
              "></el-input>
            <div @click="showInfo" v-show="showtip" class="tip">
              {{ idcardmation || "" }}
            </div>
          </el-form-item>
          <el-form-item label="学员姓名" prop="studentname">
            <el-input v-model="Form.studentname" type="text" size="small" placeholder="请输入学员姓名" :disabled="disabled" />
          </el-form-item>
          <!-- <el-form-item label="身份证号" prop="idCard">
            <el-input
              v-model="Form.idCard"
              type="text"
              size="small"
              placeholder="请输入身份证号"
              :disabled="disabledcard"
              @input="
                (val) => {
                  getIdcard(val);
                }
              "
            />
            <div @click="showInfo" v-show="showtip" class="tip">
              {{ idcardmation || "" }}
            </div>
          </el-form-item> -->
          <el-form-item label="性别" prop="sex" required>
            <el-radio-group v-model="Form.sex" disabled>
              <el-radio label="1">男</el-radio>
              <el-radio label="2">女</el-radio>
            </el-radio-group>
          </el-form-item>
          <el-form-item label="联系电话" prop="mobile" class="Classitem">
            <el-input v-model="Form.mobile" type="text" size="small" :disabled="editshow" placeholder="请输入联系电话" />
            <!-- <el-button type="text" @click="editMobile()" v-show="editshow"
              >修改</el-button
            > -->
          </el-form-item>
          <!-- <el-form label-width="120px" ref="Form" :model="Form" :rules="rules" v-show="projectSource == '40'"> -->
          <template v-if="projectSource == '40'">
            <el-form-item label="民族" prop="nation">
              <el-select v-model="Form.nation" placeholder="请选择民族" size="small">
                <el-option v-for="item in nationList" :key="item.dictCode" :label="item.dictValue" :value="item.dictCode">
                </el-option>
              </el-select>
            </el-form-item>
            <el-form-item label="住址" prop="address">
              <el-input v-model="Form.address" size="small" maxlength="50" show-word-limit placeholder="请输入住址"></el-input>
            </el-form-item>
            <el-form-item label="工作区域" prop="workingArea">
              <el-cascader clearable v-model="Form.workingArea" :options="areatreeList" :props="propsarea" size="small" />
            </el-form-item>
            <el-form-item label="工作单位" prop="workUnit">
              <el-input v-model="Form.workUnit" size="small" maxlength="50" show-word-limit
                placeholder="请输入工作单位"></el-input>
            </el-form-item>
            <el-form-item label="学员类型" prop="studentType">
              <el-select v-model="Form.studentType" placeholder="请选择学员类型" size="small">
                <el-option v-for="item in studengTypeList" :key="item.dictCode" :label="item.dictValue"
                  :value="item.dictCode">
                </el-option>
              </el-select>
            </el-form-item>
          </template>
          <el-form-item label="学员类型" prop="projectUserCode" v-if="checkProjectCc">
            <el-select v-model="Form.projectUserCode" placeholder="请选择学员类型" size="small" clearable>
              <el-option v-for="item in userTypeList" :key="item.value" :label="item.label" :value="item.value">
              </el-option>
            </el-select>
          </el-form-item>
          <el-form-item label="证件照片">
            <div class="flexdc">
              <div class="df idPhoto" style="margin-bottom: 10px">
                <div @mouseleave="showDel = false" @mouseover="showDel = true">
                  <div v-show="Form.idPhotoKey && showDel" class="flexdcc">
                    <i class="el-icon-delete" style="color: #fff; font-size: 20px" @click="delIdPhoto"></i>
                  </div>
                  <img style="width: 135px; height: 189px" :src="Form.idPhoto || require('@/assets/id_photo_example.png')
                    " alt />
                </div>
              </div>
              <div class="pic_upload">
                <el-upload :on-change="handleAvatarSuccessIdPhoto" :before-upload="beforeIdPhotoUpload"
                  :http-request="$requestMine" :show-file-list="false" class="img-el-upload" action
                  accept="image/png, image/gif, image/jpg, image/jpeg">
                  <el-button size="small" class="bgc-bv" style="margin-left: 30px">点击上传</el-button>
                </el-upload>
              </div>
            </div>
          </el-form-item>
          <el-form-item label="电子邮件" prop="email" v-if="trainTypeId != '11'">
            <el-input v-model="Form.email" type="text" size="small" placeholder="请输入电子邮件" />
          </el-form-item>
          <el-form-item label="所属公司" prop="compId" v-if="projectSource != '40' && trainTypeId == '2'">
            <el-select v-model="Form.compId" filterable clearable placeholder="请至少输入两个字搜索" size="small"
              :remote-method="getCompanyList" remote>
              <el-option v-for="item in companyList" :key="(item || {}).compId" :label="(item || {}).compName"
                :value="(item || {}).compId"></el-option>
            </el-select>
          </el-form-item>
          <el-form-item label="工作单位" prop="compId" v-if="projectSource != '40' && trainTypeId != '2'">
            <el-select v-model.trim="Form.workUnit" filterable allow-create clearable placeholder="请输入工作单位" size="small"
              :remote-method="getCompanyList" remote>
              <el-option v-for="item in companyList" :key="(item || {}).compName" :label="(item || {}).compName"
                :value="(item || {}).compName"></el-option>
            </el-select>
          </el-form-item>
          <el-form-item label="文化水平" prop="educationLevel">
            <el-select v-model="Form.educationLevel" filterable clearable placeholder="请选择文化水平" size="small">
              <el-option v-for="item in educationList" :key="item.value" :label="item.label"
                :value="item.value"></el-option>
            </el-select>
          </el-form-item>

          <template v-if="trainTypeId == '11'">
            <el-form-item label="专业" prop="profession">
              <el-input v-model="Form.profession" size="small" maxlength="50" show-word-limit
                placeholder="请输入专业"></el-input>
            </el-form-item>
            <el-form-item label="职务/名称" prop="professionTitle">
              <el-input v-model="Form.professionTitle" size="small" maxlength="50" show-word-limit
                placeholder="请输入职务/名称"></el-input>
            </el-form-item>
            <el-form-item label="部门" prop="department">
              <el-input v-model="Form.department" size="small" maxlength="50" show-word-limit
                placeholder="请输入部门"></el-input>
            </el-form-item>
            <el-form-item label="工种" prop="fullTrainWorkType">
              <el-input v-model="Form.fullTrainWorkType" size="small" maxlength="50" show-word-limit
                placeholder="请输入工种"></el-input>
            </el-form-item>
            <el-form-item label="人员类型" prop="userType">
              <el-select v-model="Form.userType" placeholder="请选择人员类型" size="small">
                <el-option v-for="item in UserTypeList" :key="item.dictCode" :label="item.dictValue"
                  :value="item.dictCode">
                </el-option>
              </el-select>
            </el-form-item>
          </template>
          <!-- 新增时的课程复选框 -->
          <el-form-item label="学习课程" prop="Coursestudy" v-if="stu == 'add'" required>
            <el-checkbox-group v-model="Form.Coursestudy" @change="selectBox">
              <el-checkbox :label="item.projectCourseId" name="type" v-for="(item, index) in Course" :key="index">{{
                item.courseName }}</el-checkbox>
            </el-checkbox-group>
          </el-form-item>
          <!-- 查看时的课程复选框 -->
          <el-form-item label="学习课程" prop="newCoursestudy" v-else required>
            <el-checkbox-group v-model="Form.newCoursestudy" @change="editchange">
              <el-checkbox :label="item.projectCourseId" name="type" v-for="(item, index) in editCourse" :key="index">{{
                item.courseName }}</el-checkbox>
            </el-checkbox-group>
          </el-form-item>
        </el-form>
      </div>
      <span slot="footer" class="dialog-footer" style="display: flex; justify-content: center">
        <el-button class="bgc-bv" @click="cancleForm('Form')">取消</el-button>
        <el-button class="bgc-bv" @click="getSure('Form')">确定</el-button>
      </span>
    </el-dialog>
    <el-dialog title="修改手机号" :visible.sync="dialogVisiblemobile" width="30%" top="15%" :center="true"
      :before-close="doCloseMobile" :close-on-click-modal="false">
      <el-form label-width="120px" ref="editForm" :model="editForm" :rules="editrules">
        <el-form-item label="新手机号" prop="editmobile">
          <el-input v-model="editForm.editmobile" type="text" size="small" placeholder="请输入手机号">
            <el-button slot="append" @click="getCode" :disabled="codeDisabled" size="small">{{ codemsg }}</el-button>
          </el-input>
        </el-form-item>
        <el-form-item label="验证码" prop="code" class="Classitem">
          <el-input v-model="editForm.code" type="text" size="small" placeholder="请输入验证码" />
        </el-form-item>
      </el-form>
      <span slot="footer" class="dialog-footer" style="display: flex; justify-content: center">
        <el-button @click="doCloseMobile()" class="bgc-bv">取消</el-button>
        <el-button class="bgc-bv" @click="editmobileSure('Form')">确定</el-button>
      </span>
    </el-dialog>
    <el-dialog :title="projectName + '学员导入'" :visible.sync="centerDialogVisible" width="50%" center
      :before-close="doCloseload" class="exportdialog">
      <div class="flexdcc">
        <div class="export-box">
          <h1>
            1
            <span>下载并填写导入模板</span>
          </h1>
          <div class="df">
            <span v-for="(item, index) in templatelist" :key="index" style="
                margin-top: 20px;
                margin-right: 20px;
                color: #409eff;
                cursor: pointer;
              " @click="downloadExcelgs(item.templatePath)">
              {{ item.templateName }}
            </span>
          </div>
        </div>
        <div class="export-box">
          <h1>
            2
            <span>导入编写好的Excel文档</span>
          </h1>
          <div class="flexdc">
            <div class="df" style="margin: 20px 0 10px">
              <el-upload class="upload-demo upload-workers" :action="actionUrl" :on-error="handleError"
                :on-success="handleSuccess" :on-change="uploadChange" :show-file-list="false" :auto-upload="false">
                <el-button class="bgc-bv" style="font-size: 12px; width: 80%">浏览</el-button>
              </el-upload>
              <p v-if="fileName" style="margin-top: 10px; margin-left: 10px">
                当前选择文件：
                <span style="color: #f46173">{{ fileName }}</span>
              </p>
              <p v-else style="margin-top: 10px; margin-left: 10px">
                未选择文件
              </p>
            </div>
            <div>
              <el-button class="bgc-bv" size="mini" style="margin-top: 10px; height: 35px" :disabled="doExportInDisabled"
                @click="doExport">开始导入</el-button>
            </div>
            <div style="margin-top: 10px" v-if="progressVal > 0">
              <el-progress :text-inside="true" :stroke-width="20" :percentage="progressVal"></el-progress>
            </div>
          </div>
        </div>
        <div class="export-box">
          <h1>
            3
            <span>导入结果</span>
          </h1>
          <div class="df studentNum">
            <span>学员总数量:{{ totalNum }}人;</span>
            <span>成功:{{ correctNum }}人;</span>
            <span>失败:{{ errorNum }}人;</span>
          </div>
          <div>
            <el-button class="bgc-bv" style="margin-top: 15px; height: 35px" size="mini" :disabled="errorNum == '0'"
              @click="doExportError">导出错误数据</el-button>
          </div>
          <div style="margin-top: 15px; color: #dd1d35" v-if="errorNum != 0">
            有导入失败学员，请导出错误数据，将导入失败学员调整后重新上传
          </div>
        </div>
      </div>
    </el-dialog>
    <el-dialog title="下载培训档案" :visible.sync="dialogDownload" width="50%" center
      :before-close="closeDownloadTrainDocument">
      <div id="pdf-content" style="height: 800px"></div>
    </el-dialog>
    <el-dialog title="下载学时证明" :visible.sync="dialogCert" width="50%" center :before-close="closeDownloadCert">
      <div id="pdf-cert" style="height: 800px"></div>
    </el-dialog>
    <!-- 全员培训档案 -->
    <el-dialog title="全员培训档案选择" :visible.sync="docsDialogVisible" width="40%" top="5%" :center="true"
      :before-close="closeDocsDialog">
      <div style="height: 500px">
        <div class="ovy-a">
          <el-form label-width="80px" ref="docsFormall" :model="docsFormall" class="docsDialog">
            <el-form-item v-for="(val, key) in docsOptionall" :key="key">
              <el-checkbox v-model="docsFormall[key]">{{ val }}</el-checkbox>
            </el-form-item>
          </el-form>
        </div>
      </div>
      <span slot="footer" class="dialog-footer" style="display: flex; justify-content: center">
        <div>
          <el-button class="bgc-bv" @click="confirmDocsDialog">确定</el-button>
          <el-button @click="closeDocsDialog">取消</el-button>
        </div>
      </span>
    </el-dialog>
    <el-dialog title="下载全员培训档案" :visible.sync="dialogDocs" width="50%" top="5%" center :before-close="closedialogDocs">
      <div id="pdf-cert" style="height: 600px"></div>
    </el-dialog>
    <!-- vueCropper 剪裁图片实现-->
    <el-dialog title="图片剪裁" :visible="dialogVisibleCropper" :modal="false" :before-close="docropperCencal"
      class="vueCropper_dialog">
      <div class="cropper-content">
        <div class="cropper" style="text-align: center">
          <vueCropper ref="cropper" :img="option.img" :outputSize="option.size" :outputType="option.outputType"
            :info="true" :full="option.full" :canMove="option.canMove" :canMoveBox="option.canMoveBox"
            :original="option.original" :autoCrop="option.autoCrop" :fixed="option.fixed"
            :fixedNumber="option.fixedNumber" :centerBox="option.centerBox" :infoTrue="option.infoTrue"
            :fixedBox="option.fixedBox" :mode="option.mode"></vueCropper>
        </div>
      </div>
      <p class="flexcb" style="margin: 5px 0 15px">
        <span style="color: #e90000">如果您上传的图片像素过高，可以将鼠标放到图片上滑动鼠标滚轮进行缩放。</span>
        <el-upload :on-change="handleAvatarSuccessIdPhoto" :before-upload="beforeIdPhotoUpload"
          :http-request="$requestMine" :show-file-list="false" class="img-el-upload" style="display: inline" action
          accept="image/png, image/gif, image/jpg, image/jpeg">
          <span style="color: #5c6be8; cursor: pointer; text-decoration: underline">重新上传</span>
        </el-upload>
      </p>
      <div class="cropper_tip">
        <h3>上传照片要求</h3>
        <div class="flexcb">
          <div class="flexac">
            <span class="tip_index">1</span>
            <span class="tip_text">人像清晰，神态自然无明显变形</span>
          </div>
          <div class="flexac">
            <span class="tip_index">2</span>
            <span class="tip_text">人像占照片尺寸2/3 纯色背景</span>
          </div>
          <div class="flexac">
            <span class="tip_index">3</span>
            <span class="tip_text">35*49mm或413*578像素 二寸照片最佳</span>
          </div>
        </div>
      </div>
      <div slot="footer" class="flexcc">
        <el-button @click="docropperCencal">取 消</el-button>
        <el-button class="bgc-bv" @click="finish">确认</el-button>
      </div>
    </el-dialog>
    <!-- 人员类别维护 -->
    <el-dialog :title="projectName + '人员类别维护'" :visible.sync="centerDialogVisiblelb" width="50%" center
      :before-close="doCloseloadlb" class="exportdialog">
      <div class="flexdcc">
        <div class="export-box">
          <h1>
            1
            <span>下载本班已完成推送学员</span>
          </h1>
          <div class="df">
            <span @click="doExportPush" style="
                margin-top: 20px;
                margin-right: 20px;
                color: #409eff;
                cursor: pointer;
              ">已结业人员</span>
          </div>
        </div>
        <div class="export-box">
          <h1>
            2
            <span>导入补充完人员类别的Excel文档</span>
          </h1>
          <div class="flexdc">
            <div class="df" style="margin: 20px 0 10px">
              <el-upload class="upload-demo upload-workers" :action="actionUrl" :on-error="handleError"
                :on-success="handleSuccess" :on-change="uploadChange1" :show-file-list="false" :auto-upload="false">
                <el-button class="bgc-bv" style="font-size: 12px; width: 80%">浏览</el-button>
              </el-upload>
              <p v-if="fileName" style="margin-top: 10px; margin-left: 10px">
                当前选择文件：
                <span style="color: #f46173">{{ fileName }}</span>
              </p>
              <p v-else style="margin-top: 10px; margin-left: 10px">
                未选择文件
              </p>
            </div>
            <div>
              <el-button class="bgc-bv" size="mini" style="margin-top: 10px; height: 35px" :disabled="doExportInDisabled"
                @click="doExportIn">开始导入</el-button>
            </div>
          </div>
        </div>
        <div class="export-box">
          <h1>
            3
            <span>导入结果</span>
          </h1>
          <div class="df studentNum">
            <span>学员总数量:{{ totalNumlb }}人;</span>
            <span>成功:{{ correctNumlb }}人;</span>
            <span>失败:{{ errorNumlb }}人;</span>
          </div>
          <div>
            <el-button class="bgc-bv" style="margin-top: 15px; height: 35px" size="mini" :disabled="errorNumlb == '0'"
              @click="doExportError1">导出错误数据</el-button>
          </div>
          <div style="margin-top: 15px; color: #dd1d35" v-if="errorNumlb != 0">
            有导入失败学员，请导出错误数据，将导入失败学员调整后重新上传
          </div>
        </div>
      </div>
    </el-dialog>
    <!-- 导入二寸照片 - 弹框 -->
    <batchStudentsUploadIdPhotos :tk="plsczjzptype" @cgbplsczjzptype="gbplsczjzptype" />
  </div>
</template>
<script>
const className = "eduCertService";
import Empty from "@/components/Empty.vue";
import PageNum from "@/components/PageNum.vue";
import List from "@/mixins/List";
import { mapGetters } from "vuex";
import pdf from "pdfobject";
import batchStudentsUploadIdPhotos from "@/components/batchStudentsUploadIdPhotos.vue";
export default {
  name: "myresourseList",
  components: {
    Empty,
    PageNum,
    batchStudentsUploadIdPhotos,
  },
  props: ["length"],
  mixins: [List],
  data() {
    return {
      date: [],
      // 证件照
      dialogVisibleCropper: false,
      cropperUrl: "",
      opendialog: true,
      option: {
        img: "", // 裁剪图片的地址
        info: true, // 裁剪框的大小信息
        outputSize: 0.8, // 裁剪生成图片的质量
        outputType: "jpeg", // 裁剪生成图片的格式
        canScale: true, // 图片是否允许滚轮缩放
        autoCrop: true, // 是否默认生成截图框
        autoCropWidth: 413, // 默认生成截图框宽度
        autoCropHeight: "100%", // 默认生成截图框高度
        fixedBox: false, // 固定截图框大小 不允许改变
        fixed: true, // 是否开启截图框宽高固定比例
        fixedNumber: [1, 1.4], // 截图框的宽高比例
        full: true, // 是否输出原图比例的截图
        canMoveBox: false, // 截图框能否拖动
        original: false, // 上传图片按照原始比例渲染
        centerBox: false, // 截图框是否被限制在图片里面
        infoTrue: true, // true 为展示真实输出图片宽高 false 展示看到的截图框宽高
        mode: "contain",
      },
      showDel: false,
      stu: "add",
      dialogTitle: "新增学员信息",
      disabled: false,
      disabledcard: false,
      showtip: false,
      editshow: false,
      dialogVisible: false,
      dialogVisiblemobile: false,
      centerDialogVisible: false,
      dialogDocs: false,
      codemsg: "获取验证码",
      auth_time: "60",
      codeDisabled: false,
      editForm: {
        editmobile: "",
        code: "",
      },
      editrules: {
        editmobile: [
          { required: true, validator: this.$validatePhone, trigger: "blur" },
        ],
        code: [{ required: true, message: "请输入验证码", trigger: "blur" }],
      },
      idcard: "",
      idcardmation: "",
      idcardObj: {},
      userJson: {},
      userName: "",
      mobile: "",
      completeState: "",
      complete: [],
      authenticationList: [],
      authenticationState: "",
      graduationState:'',
      educationList: [],
      companyList: [],
      Form: {
        idType: "10",
        studentname: "",
        idCard: "",
        sex: "1",
        mobile: "",
        email: "",
        company: "",
        compId: "",
        educationLevel: "",
        Coursestudy: [],
        newCoursestudy: [], // checkbox 默认选中的数组集合
        // portCoursestudy: [], // checkbox 默认选中的数组集合
        idPhoto: "", // 证件照
        idPhotoKey: "", // 证件照
        nation: "", // 民族
        address: "", // 住址
        workUnit: "", // 工作单位
        studentType: "", // 学员类型
        workingArea: "", // 工作区域
        profession: "", //专业
        professionTitle: "", //职称
        department: "", //部门
        fullTrainWorkType: "", //工种
        userType: "", // 人员类型
        projectUserCode: "", //学员类型
      },
      rules: {
        idType: [
          { required: true, message: "请选择证件类型", trigger: "change" },
        ],
        studentname: [
          { required: true, message: "请输入学员姓名", trigger: "blur" },
        ],
        zjlx: [
          { required: true, message: "请选择证件类型", trigger: "change" },
        ],
        idCard: [
          { required: true, validator: this.$validateIdCard, trigger: "blur" },
        ],
        sex: [{ required: true, message: "请选择性别", trigger: "change" }],
        mobile: [
          { required: true, validator: this.$validatePhone, trigger: "blur" },
        ],
        Coursestudy: [
          { required: true, message: "请至少选择一项课程", trigger: "change" },
        ],
        newCoursestudy: [
          { required: true, message: "请至少选择一项课程", trigger: "change" },
        ],
        workingArea: [
          { required: true, message: "请选择工作区域", trigger: "change" },
        ],
        workUnit: [
          { required: true, message: "请输入工作单位", trigger: "blur" },
        ],
        studentType: [
          { required: true, message: "请选择学员类型", trigger: "change" },
        ],
        projectUserCode: [
          { required: true, message: "请选择学员类型", trigger: "change" },
        ],
        //  profession: [
        //     { required: true, message: "请输入专业", trigger: "blur" },
        //   ],
        //   professionTitle: [
        //     { required: true, message: "请输入职称", trigger: "blur" },
        //   ],
        //   department: [
        //     { required: true, message: "请输入部门", trigger: "blur" },
        //   ],
        //   workType: [
        //     { required: true, message: "请输入工种", trigger: "blur" },
        //   ],
        //   userType: [
        //     { required: true, message: "请选择人员类型", trigger: "change" },
        //   ],
        // portCoursestudy: [
        //   { required: true, message: "请至少选择一项课程", trigger: "change" },
        // ],
      },
      activeName: "first",
      props: {
        value: "id",
        label: "label",
        emitPath: false,
      },
      values: [], // 存储value的数组
      newvalues: [], // 存储value的数组
      Course: [], //
      editCourse: [], // 查看编辑时的课程数组
      editvalues: [],
      editvaluesfalse: [],
      projectUserCourseId: "",
      fileName: "",
      fileKey: "",
      excelUrl: "",
      dialogDownload: false,
      dialogCert: false,
      projectName: "",
      courseCount: 0,
      docsFormall: {},
      docsDialogVisible: false,
      selectedItem: {},
      docsOptionall: {
        10: "全员培训档案首页",
        15: "生产经营单位新入职从业人员登记表",
        20: "厂（矿）级安全培训考核记录",
        25: "车间（工段、区、队）级安全培训考核记录",
        30: "班组级安全培训考核记录",
        35: "一般行业生产经营单位新入职从业人员安全培训考核记录",
        40: "生产经营单位从业人员年度安全再培训考核记录",
        45: "生产经营单位从业人员调岗和重新上岗安全培训考核记录(车间级)",
        50: "生产经营单位从业人员调岗和重新上岗安全培训考核记录(班组级)",
        55: "生产经营单位从业人员“四新”安全培训考核记录",
        60: "生产经营单位从业人员专项安全培训考核记录",
        65: "特种作业人员培训信息登记及证件真伪核查表",
        70: "生产经营单位从业人员安全生产奖惩记录",
      },
      totalNum: "0",
      errorNum: "0",
      correctNum: "0",
      batchId: "",
      importType: "",
      signEndDateLong: "",
      auditState: "",
      nationList: [], // 民族数据
      studentTypeList: [], //学员类型
      areatreeList: [], //工作区划
      propsarea: {
        value: "value",
        label: "label",
        emitPath: false,
        checkStrictly: false,
      },
      //人员类别
      centerDialogVisiblelb: false,
      totalNumlb: 0,
      correctNumlb: 0,
      errorNumlb: 0,
      progressVal: 0,
      doExportInDisabled: false, //开始导入按钮禁用

      templatelist: [], // 模板数据
      hasIdPhotoSelect: [
        {
          value: true,
          label: "有",
        },
        {
          value: false,
          label: "无",
        },
      ],
      hasIdPhoto: "",
      // 导入二寸照片 - 弹框
      plsczjzptype: false,
      userTypeList: [],
      checkProjectCc: false, //判断吉林班培训类型显示
      EduUserCardList: [], //证件类型
      Exportstudent2Button: false, // 是否显示导入报名学员按钮
    };
  },
  created() {
    this.signEndDateLong = sessionStorage.getItem("signEndDateLong");
    const query = this.$route.query;
    if (query.active == "three") {
      this.openOnce();
    }
  },
  computed: {
    ...mapGetters({
      downloadItems: "getDownloadItems",
    }),
  },
  methods: {
    //检查是否长春班级 培训类型为4以下的类型
    checkProject(projectId) {
      this.$post("/biz/project/judgeProject", { projectId }).then((res) => {
        if (res.status == 0) {
          this.checkProjectCc = res.data;
        }
      });
    },
    // 每天弹一次  别删
    openOnce() {
      //每天首次进入的时候弹提示--------- 开始
      let userId = JSON.parse(sessionStorage.getItem("userJson")).userId;
      let user = JSON.parse(localStorage.getItem(userId)) || {};
      //获取当前时间
      let date = new Date();
      let year = date.getFullYear();
      let month = date.getMonth() + 1;
      let day = date.getDate();
      if (month < 10) {
        month = "0" + month;
      }
      if (day < 10) {
        day = "0" + day;
      }
      let nowDate = year + "-" + month + "-" + day;

      if (!localStorage.getItem(userId)) {
        this.$confirm(
          "根据有关部门业务开展需要，相关数据会涉及个人信息、照片等敏感信息，导出数据仅限于业务范围内使用，不得用于其他用途，如因个人原因导致数据泄露，由个人承担法律责任。",
          "提示",
          {
            showClose: false, //是否显示右上角关闭按钮
            showCancelButton: false, //是否显示取消按钮
            closeOnClickModal: false, //是否可通过点击遮罩关闭 MessageBox
            confirmButtonText: "同意并继续",
            confirmButtonClass: "bgc-bv",
          }
        )
          .then(() => {
            let userKey = {};
            userKey["StudentList"] = nowDate;
            localStorage.setItem(userId, JSON.stringify(userKey));
          })
          .catch(() => { });
      } else if (user.StudentList == undefined || user.StudentList < nowDate) {
        this.$confirm(
          "根据有关部门业务开展需要，相关数据会涉及个人信息、照片等敏感信息，导出数据仅限于业务范围内使用，不得用于其他用途，如因个人原因导致数据泄露，由个人承担法律责任。",
          "提示",
          {
            showClose: false, //是否显示右上角关闭按钮
            showCancelButton: false, //是否显示取消按钮
            closeOnClickModal: false, //是否可通过点击遮罩关闭 MessageBox
            confirmButtonText: "同意并继续",
            confirmButtonClass: "bgc-bv",
          }
        )
          .then(() => {
            let userKey = JSON.parse(localStorage.getItem(userId));
            userKey.StudentList = nowDate;
            localStorage.setItem(userId, JSON.stringify(userKey));
          })
          .catch(() => { });
      }
      //每天首次进入的时候弹提示-------- 结束
    },
    // 截取字符串前几位
    getString2(str, lengthNum) {
      return str.substr(0, lengthNum);
    },
    init() {
      this.projectId = sessionStorage.getItem("projectId");
      this.areaId = sessionStorage.getItem("areaId");
      this.projectName = sessionStorage.getItem("projectName");
      this.courseCount = sessionStorage.getItem("courseCount");
      this.trainTypeId = sessionStorage.getItem("trainTypeId");
      this.projectSource = sessionStorage.getItem("projectSource");
      this.auditState = sessionStorage.getItem("auditState");
      this.getTableHeight();
      this.getcompleteList();
      this.getprojectCourse();
      this.getNationList();
      this.getstudengTypeList();
      this.getUserTypeList();
      this.getareatree();
      // this.getTemplate(this.projectId); 
      this.checkProject(this.projectId);
      this.getData(-1);
      this.shouHideButton();
    },
    /**
     * 导出档案编号
     */
    exportNum() {
      this.$confirm(
        "导出数据仅限于业务范围内使用，不得用于其它用途，如因个人原因导致数据泄露，由个人承担法律责任。",
        "导出提示",
        {
          confirmButtonText: "导出",
          confirmButtonClass: "confirmButtonClass",
          cancelButtonText: "取消",
        }
      )
        .then(() => {
          const params = {
            pageNum: this.pageNum,
            pageSize: this.pageSize,
            projectId: this.projectId,
            hasIdPhoto: this.hasIdPhoto,
            graduationState: this.graduationState,
          };
          if (this.userName) {
            params.userName = this.userName;
          }
          if (this.idcard) {
            params.idcard = this.idcard;
          }
          if (this.mobile) {
            params.mobile = this.mobile;
          }
          if (this.authenticationState) {
            params.authentication = this.authenticationState;
          }
          if (this.completeState) {
            params.completeState = this.completeState;
          }
          if (this.date) {
            params.startDate = this.date[0];
            params.endDate = this.date[1];
          }
          this.$post(
            "/biz/projectUser/exportProjectUserCertNoList",
            params
          ).then((res) => {
            if (res.status == "0") {
              window.open(res.data);
            } else {
              this.$message({
                type: "error",
                message: res.message,
              });
            }
          });
        })
        .catch(() => { });
    },
    /**
     * 导出班级学员
     */
    exportClassStudent() {
      this.$confirm(
        "导出数据仅限于业务范围内使用，不得用于其它用途，如因个人原因导致数据泄露，由个人承担法律责任。",
        "导出提示",
        {
          confirmButtonText: "导出",
          confirmButtonClass: "confirmButtonClass",
          cancelButtonText: "取消",
        }
      )
        .then(() => {
          const params = {
            pageNum: this.pageNum,
            pageSize: this.pageSize,
            projectId: this.projectId,
            hasIdPhoto: this.hasIdPhoto,
            graduationState: this.graduationState,
          };
          if (this.userName) {
            params.userName = this.userName;
          }
          if (this.idcard) {
            params.idcard = this.idcard;
          }
          if (this.mobile) {
            params.mobile = this.mobile;
          }
          if (this.authenticationState) {
            params.authentication = this.authenticationState;
          }
          if (this.completeState) {
            params.completeState = this.completeState;
          }
          if (this.date) {
            params.startDate = this.date[0];
            params.endDate = this.date[1];
          }
          this.$post("/biz/projectUser/projectUserReport", params).then(
            (res) => {
              // if (res.status == "0") {
              //   window.open(res.data);
              // } else {
              //   this.$message({
              //     type: "error",
              //     message: res.message,
              //   });
              // }
              if (res.status == "0") {
                let list = res.data;
                this.editPopupDialogVisible = false;
                if (!this.downloadItems.includes(list.taskId)) {
                  this.$store.dispatch("pushDownloadItems", list.taskId);
                } else {
                  this.$message.warning(
                    "[" + list.fileName + "]已经申请下载,请耐心等待"
                  );
                }
              } else {
                this.$message.error(res.message);
              }
            }
          );
        })
        .catch(() => { });
    },
    /**
     * 证件照
     */
    urltoBase64(res) {
      const that = this;
      const reader = new FileReader();
      reader.readAsDataURL(res.raw);
      reader.onload = function (e) {
        that.option.img = e.target.result;
      };
    },
    // 上传证件照
    handleAvatarSuccessIdPhoto(res) {
      this.urltoBase64(res);
      if (this.opendialog) {
        this.dialogVisibleCropper = true;
      }
    },
    upload(res) {
      let extension = res.name.substring(res.name.lastIndexOf(".") + 1);
      let formData = new FormData();
      formData.append("folder ", "USER");
      formData.append("file ", res.raw);
      formData.append("fileType ", extension);
      this.$Postformat("/sys/upload", formData)
        .then((result) => {
          this.Form.idPhoto = result.data.fileURL;
          this.Form.idPhotoKey = result.data.fileKey;
          // this.urltoBase64(res);
          // if (this.opendialog) {
          //   this.dialogVisibleCropper = true;
          // }
        })
        .catch((err) => {
          setTimeout(() => {
            this.$message({
              type: "warning",
              message: "上传失败",
            });
          }, 300);
        });
    },
    beforeIdPhotoUpload(file) {
      this.opendialog = true;
    },
    // 点击裁剪，这一步是可以拿到处理后的地址
    finish() {
      this.$refs.cropper.getCropBlob((data) => {
        let myFile = new window.File([data], this.Form.studentname + ".jpeg");
        this.cropperFile = myFile;
        this.upload({
          name: this.Form.studentname + ".jpeg",
          raw: myFile,
        });
        this.dialogVisibleCropper = false;
      });
    },
    docropperCencal() {
      this.opendialog = true;
      this.dialogVisibleCropper = false;
    },
    delIdPhoto() {
      this.Form.idPhoto = "";
      this.Form.idPhotoKey = "";
    },
    //
    // 获取学习状态&& 学历
    getcompleteList() {
      const studylist = this.$setDictionary("LEARNINGSTATE", "list");
      const educationList = this.$setDictionary("EDUCATION", "list");
      const authentication = this.$setDictionary("AUTHENTICATION", "list");
      const userType = this.$setDictionary("USERTYPE_JL", "list");
      const eduIdType = this.$setDictionary("EDU_USER_CARD_TYPE", "list");
      const list = [];
      const eduList = [];
      const authenticationList = [];
      for (const key in studylist) {
        list.push({
          value: key,
          label: studylist[key],
        });
      }
      for (const key in educationList) {
        eduList.push({
          value: key,
          label: educationList[key],
        });
      }
      for (const key in authentication) {
        authenticationList.push({
          value: key,
          label: authentication[key],
        });
      }
      for (const key in userType) {
        this.userTypeList.push({
          value: key,
          label: userType[key],
        });
      }
      for (const key in eduIdType) {
        this.EduUserCardList.push({
          value: key,
          label: eduIdType[key],
        });
      }
      this.educationList = eduList;
      this.complete = list;
      this.authenticationList = authenticationList;
    },
    //列表数据
    getData(pageNum) {
      // sessionStorage.setItem("refresh", 10);
      const params = {
        pageNum: pageNum,
        pageSize: this.pageSize,
        projectId: this.projectId,
        hasIdPhoto: this.hasIdPhoto,
        graduationState: this.graduationState,
      };
      if (this.userName) {
        params.userName = this.userName;
      }
      if (this.idcard) {
        params.idcard = this.idcard;
      }
      if (this.mobile) {
        params.mobile = this.mobile;
      }
      if (this.completeState) {
        params.completeState = this.completeState;
      }
      if (this.authenticationState) {
        params.authentication = this.authenticationState;
      }
      if (this.date) {
        params.startDate = this.date[0];
        params.endDate = this.date[1];
      }
      this.doFetch({
        url: "/biz/projectUser/listPage",
        params,
        pageNum,
      });
    },
    //获取课程
    getprojectCourse() {
      this.$post("/biz/projectCourse/queryCourseListByProjectId", {
        projectId: this.projectId,
      })
        .then((ret) => {
          this.Course = ret.data || [];
        })
        .catch((err) => {
          return;
        });
    },
    // 查询公司
    getCompanyList(query) {
      if (query.trim().length >= 2) {
        this.$post("/sys/company/queryCompanyList", { compName: query })
          .then((res) => {
            if (res.status == 0) {
              this.companyList = res.data || [];
            }
          })
          .catch(() => {
            return;
          });
      } else {
        this.companyList = [];
      }
    },
    /**
     * 单位回显接口
     */
    getCompany(id) {
      this.$post("/sys/company/id", { compId: id }).then((res) => {
        this.companyList = [res.data];
      });
    },
    formatterDate(date) {
      let result = new Date(date);
      return result;
    },
    // 新增学员
    handleAdd(stu) {
      this.stu = stu;
      let day = this.signEndDateLong.slice(0, 10);
      let curDay = this.$moment().format("YYYY/MM/DD");
      if (curDay > day) {
        this.$alert("已过报名截止日期", "提示", {
          confirmButtonText: "确定",
          confirmButtonClass: "confirmButtonClass",
        });
      } else {
        if (sessionStorage.getItem("courseCount") == 0) {
          this.$alert("该班级还未配置课程", "提示", {
            confirmButtonText: "确定",
            confirmButtonClass: "confirmButtonClass",
          });
        } else {
          this.dialogTitle = "新增学员信息";
          this.dialogVisible = true;
          this.disabled = false;
          this.disabledcard = false;
          this.editshow = false;
          this.Form.idType = "10";
          this.Course.map((item) => {
            this.Form.Coursestudy.push(item.projectCourseId);
            this.values.push(item);
          });
        }
      }
    },
    reset() {
      this.Form = {
        idType: "10",
        studentname: "",
        idCard: "",
        sex: "1",
        mobile: "",
        email: "",
        company: "",
        compId: "",
        educationLevel: "",
        Coursestudy: [],
        newCoursestudy: [], // checkbox 默认选中的数组集合
        idPhoto: "", // 证件照
        idPhotoKey: "", // 证件照
        nation: "", // 民族
        address: "", // 住址
        workUnit: "", // 工作单位
        studentType: "", // 学员类型
        workingArea: "", // 工作区域
        profession: "", //专业
        professionTitle: "", //职称
        department: "", //部门
        workType: "", //工种
        userType: "", // 人员类型
        // portCoursestudy: [], // checkbox 默认选中的数组集合
      };
      this.values = [];
    },
    getSure(formName) {
      this.$refs[formName].validate((valid) => {
        if (valid) {
          this.$post("/biz/projectUser/repeatTraining", {
            idcard: this.Form.idCard,
            projectId: this.projectId,
          }).then((ret) => {
            if (ret.data == true) {
              this.$confirm(
                "该学员已在同一工种和同一培训等级的培训班级报过名，请确认是否继续新增?",
                "提示",
                {
                  confirmButtonText: "确定",
                  cancelButtonText: "取消",
                  type: "warning",
                }
              )
                .then(() => {
                  this.doeAjax();
                })
                .catch(() => {
                  // this.$message({
                  //   type: "info",
                  //   message: "已取消删除"
                  // });
                });
            } else {
              this.doeAjax();
            }
          });
        }
      });
    },
    doeAjax() {
      const parmar = {
        idType: this.Form.idType,
        userName: this.Form.studentname,
        idcard: this.Form.idCard,
        sex: this.Form.sex,
        mobile: this.Form.mobile,
      };
      if (this.stu == "add") {
        if (this.projectId) {
          parmar.projectId = this.projectId;
        }
        if (this.Form.Coursestudy) {
          parmar.courseList = this.values;
        }
        if (this.Form.userId) {
          parmar.userId = this.Form.userId;
        }
      } else {
        if (this.projectUserCourseId) {
          parmar.projectUserCourseId = this.projectUserCourseId;
        }
        if (this.Form.newCoursestudy) {
          parmar.selectProjectCourses = this.editvalues;
          parmar.disSelectProjectCourses = this.editvaluesfalse;
        }
      }
      if (this.Form.email) {
        parmar.email = this.Form.email;
      }
      if (this.Form.compId) {
        parmar.compId = this.Form.compId;
      }
      if (this.Form.educationLevel) {
        parmar.educationLevel = this.Form.educationLevel;
      }
      //idPhoto: "", // 证件照
      //idPhotoKey: "" // 证件照
      if (this.Form.idPhoto) {
        parmar.idPhoto = this.Form.idPhoto;
      }
      if (this.Form.idPhotoKey) {
        parmar.idPhotoKey = this.Form.idPhotoKey;
      }
      if (this.Form.nation) {
        parmar.nation = this.Form.nation;
      }
      if (this.Form.workUnit) {
        parmar.workUnit = this.Form.workUnit;
      }
      if (this.Form.studentType) {
        parmar.studentType = this.Form.studentType;
      }
      if (this.Form.address) {
        parmar.address = this.Form.address;
      }
      if (this.Form.workingArea) {
        parmar.workingArea = this.Form.workingArea;
      }
      if (this.Form.projectUserCode) {
        parmar.projectUserCode = this.Form.projectUserCode;
      }
      if (this.trainTypeId == "11") {
        if (this.Form.profession) {
          parmar.profession = this.Form.profession;
        }
        if (this.Form.professionTitle) {
          parmar.professionTitle = this.Form.professionTitle;
        }
        if (this.Form.department) {
          parmar.department = this.Form.department;
        }
        if (this.Form.fullTrainWorkType) {
          parmar.fullTrainWorkType = this.Form.fullTrainWorkType;
        }
        if (this.Form.userType) {
          parmar.userType = this.Form.userType;
        }
      }
      this.$post(
        this.stu == "add"
          ? "/biz/projectUser/addCourseUser"
          : "biz/projectUser/updateCourseUser",
        parmar
      )
        .then((ret) => {
          if (ret.status == 0) {
            this.$message({
              message: ret.message,
              type: "success",
            });
            this.dialogVisible = false;
            // this.doClose();
            this.getData(-1);
            this.reset();
          }
        })
        .catch((err) => {
          return;
        });
    },
    //  新增是的复选框事件
    selectBox(val) {
      this.values = [];
      this.Course.forEach((items) => {
        if (val.indexOf(items.projectCourseId) != -1) {
          let obj = {
            projectCourseId: items.projectCourseId,
            courseName: items.courseName,
            courseId: items.courseId,
          };
          this.values.push(obj);
        }
      });
    },
    // 查看编辑时的复选框事件
    editchange(val) {
      this.editvalues = [];
      this.editvaluesfalse = [];
      this.editCourse.forEach((ele) => {
        if (val.indexOf(ele.projectCourseId) != -1) {
          let obj = {
            projectCourseId: ele.projectCourseId,
            courseName: ele.courseName,
            selected: true,
          };
          if (ele.projectUserCourseId) {
            obj.projectUserCourseId = ele.projectUserCourseId;
          }
          this.editvalues.push(obj);
        }
        if (val.indexOf(ele.projectCourseId) == -1) {
          let obj = {
            projectCourseId: ele.projectCourseId,
            courseName: ele.courseName,
            selected: false,
          };
          if (ele.projectUserCourseId) {
            obj.projectUserCourseId = ele.projectUserCourseId;
          }
          this.editvaluesfalse.push(obj);
        }
      });
    },

    // 从本班移除
    handleDel(userId) {
      this.doDel({
        url: "/biz/projectUser/removeCourseUser",
        msg: "你确定要将该学员从本班移除吗？",
        ps: {
          type: "post",
          data: { userId, projectId: this.projectId },
        },
      });
    },
    Learningrecords(row) {
      sessionStorage.setItem("row", JSON.stringify(row));
      this.$router.push({
        path: "/web/Learningrecords",
      });
    },
    getIdcard(newValue) {
      if (newValue.length == 18) {
        this.blurcard(this.Form.idCard);
        this.$post("/biz/user/queryUserByIdCard", {
          idcard: this.Form.idCard,
        })
          .then((res) => {
            if (!res.data) {
              this.showtip = false;
            } else {
              this.showtip = true;
              this.idcardObj = res.data;
              this.idcardmation =
                res.data.userName +
                "/" +
                res.data.idcard +
                "/" +
                res.data.mobile || "";
            }
          })
          .catch((err) => {
            return;
          });
      } else {
        this.showtip = false;
      }
    },
    showInfo() {
      this.showtip = false;
      this.Form.idPhotoKey = this.idcardObj.idPhotoKey;
      this.Form.idPhoto = this.idcardObj.idPhotoUrl;
      this.Form.studentname = this.idcardObj.userName;
      this.Form.idCard = this.idcardObj.idcard;
      this.Form.sex = this.idcardObj.sex;
      this.Form.mobile = this.idcardObj.mobile;
      this.Form.email = this.idcardObj.email;
      this.Form.nation = this.idcardObj.nation;
      this.Form.address = this.idcardObj.address;
      this.Form.workUnit = this.idcardObj.workUnit;
      this.Form.studentType = this.idcardObj.studentType
        ? this.idcardObj.studentType.toString()
        : "";
      this.Form.workingArea = this.idcardObj.workingArea;
      this.Form.educationLevel = this.idcardObj.educationLevel;
      this.Form.userId = this.idcardObj.userId;
      this.Form.compId = this.idcardObj.compId;
      this.Form.profession = this.idcardObj.profession || ""; //专业
      this.Form.professionTitle = this.idcardObj.professionTitle || ""; //职称
      this.Form.department = this.idcardObj.department || ""; //部门
      this.Form.fullTrainWorkType = this.idcardObj.fullTrainWorkType || ""; //工种
      this.Form.userType = this.idcardObj.userType || ""; // 人员类型
      if (this.idcardObj.compId) {
        this.getCompany(this.idcardObj.compId);
      }
    },
    //取消
    cancleForm(Form) {
      this.dialogVisible = false;
      this.$refs["Form"].resetFields();
      this.idcard = "";
      this.idcardObj = {};
      this.idcardmation = "";
      this.showtip = false;
      const course = [];
      const course3 = [];
      this.Course.map((item) => {
        course.push(item.projectCourseId);
        course3.push(item.projectCourseId);
      });
      const course2 = [];
      this.editCourse.map((item) => {
        if (course2.indexOf(item.projectCourseId) !== -1) {
          if (item.selected == true) {
            course2.push(item.projectCourseId);
          }
        }
      });
      this.Form = {
        studentname: "",
        educationLevel: "",
        idCard: "",
        sex: "1",
        mobile: "",
        email: "",
        company: "",
        compId: "",
        Coursestudy: course || [],
        newCoursestudy: course2 || [],
        nation: "", // 民族
        address: "", // 住址
        workUnit: "", // 工作单位
        studentType: "", // 学员类型
        workingArea: "", // 工作区域
        idPhoto: "", // 证件照
        idPhotoKey: "", // 证件照
        // portCoursestudy: course3 || [],
      };
      this.getData(-1);
    },
    doClose() {
      this.dialogVisible = false;
      this.$refs["Form"].resetFields();
      this.idCard = "";
      this.idcardObj = {};
      this.idcardmation = "";
      this.showtip = false;
      const course2 = [];
      const course = [];
      this.editCourse.map((item) => {
        if (item.selected == true) {
          course2.push(item.projectCourseId);
        }
      });
      this.Course.map((item) => {
        course.push(item.projectCourseId);
      });
      this.Form = {
        studentname: "",
        educationLevel: "",
        idCard: "",
        sex: "1",
        mobile: "",
        email: "",
        company: "",
        compId: "",
        Coursestudy: [],
        newCoursestudy: [],
        idPhoto: "", // 证件照
        idPhotoKey: "", // 证件照
        nation: "", // 民族
        address: "", // 住址
        workUnit: "", // 工作单位
        studentType: "", // 学员类型
        workingArea: "", // 工作区域
        // portCoursestudy: [],
      };

      this.values = [];
      this.getData(-1);
    },
    doCloseMobile() {
      // this.dialogVisible = false;
      this.dialogVisiblemobile = false;
      this.$refs["editForm"].resetFields();
      this.editForm = {
        editmobile: "",
        code: "",
      };
      this.codemsg = "获取验证码";
      this.codeDisabled = false;
    },
    // 查看
    lookstudent(projectUserCourseId, authentication, stu) {
      this.authentication = authentication;
      this.Form.newCoursestudy = [];
      this.$forceUpdate();
      this.projectUserCourseId = projectUserCourseId;
      this.stu = stu;
      this.dialogTitle = "查看学员信息";
      this.dialogVisible = true;
      this.$post("/biz/projectUser/queryCourseUser", { projectUserCourseId })
        .then((ret) => {
          let records = ret.data;
          this.Form.studentname = records.userName;
          this.Form.idCard = records.idcard;
          this.Form.sex = records.sex;
          this.Form.mobile = records.mobile;
          this.Form.email = records.email;
          this.Form.company = records.compName || "";
          this.Form.compId = records.compId || "";
          this.Form.educationLevel = records.educationLevel || "";
          this.Form.userId = records.userId || "";
          this.Form.idPhoto = records.idPhoto || "";
          this.Form.idPhotoKey = records.idPhotoKey || "";
          this.Form.nation = records.nation || "";
          this.Form.address = records.address || "";
          this.Form.workUnit = records.workUnit || "";
          this.Form.studentType = records.studentType
            ? records.studentType.toString()
            : "";
          this.Form.workingArea = records.workingArea || "";
          // this.Form.projectUserCode = records.projectUserCode || "";
          this.$set(this.Form, "idType", records.idType || "10"); //证件类型
          this.$set(this.Form, "profession", records.profession || ""); //专业
          this.$set(
            this.Form,
            "professionTitle",
            records.professionTitle || ""
          ); //职称
          this.$set(this.Form, "department", records.department || ""); //部门
          this.$set(
            this.Form,
            "fullTrainWorkType",
            records.fullTrainWorkType || ""
          ); //工种
          this.$set(this.Form, "userType", records.userType || ""); //人员类型
          this.$set(
            this.Form,
            "projectUserCode",
            records.projectUserCode || ""
          ); //学员类型
          this.editCourse = records.studyCourses;
          this.editCourse.forEach((item) => {
            if (
              this.Form.newCoursestudy.indexOf(item.projectCourseId) == -1 &&
              item.selected == true
            ) {
              this.Form.newCoursestudy.push(item.projectCourseId);
            }
          });
          if (records.compId) {
            this.getCompany(records.compId);
          }
          // 大能手
          if (this.projectSource == '60') {
            this.editshow = true;
            this.disabled = true;
            this.disabledcard = true;
          } else {
            // 未实名认证，未登录过
            if (authentication != "20" && !records.isLogin) {
              this.editshow = false;
              this.disabled = false;
              this.disabledcard = false;
            } else if (authentication != "20") {
              this.editshow = true;
              this.disabled = false;
              this.disabledcard = false;
            } else {
              this.editshow = true;
              this.disabled = true;
              this.disabledcard = true;
            }
          }
          this.$forceUpdate();
        })
        .catch((err) => {
          return;
        });
    },
    blurcard(e) {
      let sex = "";
      if (parseInt(e.substr(16, 1)) % 2 == 1) {
        //男
        sex = "1";
      } else {
        //女
        sex = "2";
      }
      this.Form.sex = sex;
    },
    /* 修改手机号 */
    editMobile() {
      this.dialogVisiblemobile = true;
    },
    getAuthCode() {
      if (this.authTimeTimer) {
        clearTimeout(this.authTimeTimer);
      }
      this.authTimeTimer = setTimeout(() => {
        this.auth_time -= 1;
        this.codemsg = this.auth_time + "s";
        this.codeDisabled = true;
        if (this.auth_time < 0) {
          this.codeDisabled = false;
          this.codemsg = "获取验证码";
          clearTimeout(this.authTimeTimer);
        } else {
          this.getAuthCode();
        }
      }, 1000);
    },
    getCode() {
      this.$refs.editForm.validateField("editmobile", (phoneError) => {
        if (!phoneError) {
          this.auth_time = 60;
          this.codeDisabled = false;
          this.$post("/sys/sms/captcha", { mobile: this.editForm.editmobile })
            .then((ret) => {
              this.getAuthCode();
              this.$confirm("验证码已发送至手机号上，请查收。", {
                confirmButonText: "确定",
                center: true,
                confirmButtonClass: "bgc-bv",
              });
            })
            .catch((err) => {
              return;
            });
        }
      });
    },
    editmobileSure(formName) {
      this.$refs[formName].validate((valid) => {
        if (valid) {
          this.$post("/biz/user/modifyMobileByCode", {
            code: this.editForm.code,
            mobile: this.editForm.editmobile,
            userId: this.Form.userId,
          })
            .then((ret) => {
              this.dialogVisiblemobile = false;
              this.dialogVisible = false;
              this.cancleForm("Form");
              this.codemsg = "获取验证码";
              this.codeDisabled = false;
              this.$message({
                message: "修改成功",
                type: "success",
              });
              this.editForm = {};
              // this.lookstudent(this.projectUserCourseId);
              this.$forceUpdate();
            })
            .catch((err) => {
              return;
            });
        } else {
          return false;
        }
      });
    },
    /* 修改手机号end */
    getTableHeight(opDom = true, page = true) {
      let tHeight =
        window.innerHeight - (3 + 1.35 + 3.25 + 0.675 + 2 + 1.35 + 10.5) * 16;
      if (opDom) {
        tHeight -= 40 + 0.675 * 16;
      }
      if (page) {
        tHeight -= 32;
      }
      this.tableHeight = tHeight;
    },
    /* 导入 */
    Exportstudent() {
      this.totalNum = "0";
      this.errorNum = "0";
      this.correctNum = "0";
      this.batchId = "";
      let day = this.signEndDateLong.slice(0, 10);
      let curDay = this.$moment().format("YYYY/MM/DD");
      if (curDay > day) {
        this.$alert("已过报名截止日期", "提示", {
          confirmButtonText: "确定",
          confirmButtonClass: "confirmButtonClass",
        });
      } else {
        if (sessionStorage.getItem("courseCount") == 0) {
          this.$alert("该班级还未配置课程", "提示", {
            confirmButtonText: "确定",
            confirmButtonClass: "confirmButtonClass",
          });
        } else {
          this.centerDialogVisible = true;
          this.newvalues = [];
          this.Course.map((item) => {
            // this.Form.portCoursestudy.push(item.projectCourseId);
            this.newvalues.push(item);
          });
          // 获取模板
          this.$post("/biz/project/user/v2/template", {
            projectId: this.projectId,
          })
            .then((res) => {
              this.templatelist = res.data || [];
            })
            .catch((err) => {
              this.templatelist = [];
              return;
            });
        }
      }
    },
    // 判断 - 导入报名学员 - 这个按钮是否能显示
    shouHideButton() {
      this.$post("/biz/projectUser/addApplyUserShow", {
        projectId: this.projectId,
      })
        .then((res) => {
          this.Exportstudent2Button = res.data;
        })
        .catch((err) => {
          return;
        });
    },
    // 导入报名学员
    Exportstudent2() {
      let day = this.signEndDateLong.slice(0, 10);
      let curDay = this.$moment().format("YYYY/MM/DD");
      if (curDay > day) {
        this.$alert("已过报名截止日期", "提示", {
          confirmButtonText: "确定",
          confirmButtonClass: "confirmButtonClass",
        });
        return;
      }
      if (sessionStorage.getItem("courseCount") == 0) {
        this.$alert("该班级还未配置课程", "提示", {
          confirmButtonText: "确定",
          confirmButtonClass: "confirmButtonClass",
        });
        return;
      }
      this.$router.push({
        path: "/web/classListCom/classDetailsFile/importRegisteredStudents",
        query: {
          projectId: this.projectId
        }
      });
    },
    downloadExcel() {
      /**
       * 下载模板
       * @param param 参数
       */
      let link = document.createElement("a");
      link.style.display = "none";
      link.href = "/static/student.xls";
      link.setAttribute("download", "student.xls");
      document.body.appendChild(link);
      link.click();
    },
    downloadExcelgs(templatePath) {
      /**
       * 下载模板
       * @param param 参数Import registered students
       */
      let link = document.createElement("a");
      link.style.display = "none";
      link.href = templatePath;
      let templateNames = templatePath.slice(8);
      console.log(templateNames);
      link.setAttribute("download", templateNames);
      document.body.appendChild(link);
      link.click();
    },
    // 导入学员
    uploadChange(file) {
      this.fileName = file.name;
      let size = file.size / 1024 / 1024;
      // let fileName = file.name.split(".");
      let extension = file.name.substring(file.name.lastIndexOf(".") + 1);
      const isXLSX = extension === "XLSX";
      const isxlsx = extension === "xlsx";
      const isXLS = extension === "XLS";
      const isxls = extension === "xls";
      if (!isXLSX && !isxlsx && !isXLS && !isxls) {
        this.$message.error("只能上传后缀是.xlsx或者.xls的文件");
        return;
      }
      if (size > 3) {
        this.$message.error("文件大小不能超过3M");
        return;
      }
      let formData = new FormData();
      formData.append("folder ", "USER");
      formData.append("file ", file.raw);
      formData.append("fileType ", extension);

      this.$Postformat("/sys/upload", formData)
        .then((result) => {
          // console.log(result);
          this.fileKey = result.data.fileKey;
          // this.fileName = result.data.fileKey;
        })
        .catch((err) => {
          return;
        });
    },
    doExport() {
      if (this.fileKey == "") {
        this.$message({
          message: "请选择文件",
          type: "warning",
        });
      } else {
        this.$confirm("是否将此表中学员绑定此班级?", "提示", {
          confirmButtonText: "确定",
          confirmButtonClass: "confirmButtonClass",
          cancelButtonText: "取消",
          type: "warning",
        })
          .then(() => {
            this.doExportInDisabled = true;
            if (this.fileKey) {
              const parmar = {
                projectId: this.projectId,
                excelUrl: this.fileKey,
              };
              this.$post("/biz/project/user/v2/import", parmar, 5000)
                .then((res) => {
                  if (res.status == 0) {
                    if (res.status == 0) {
                      console.log(res.data);
                      this.doProgress(res.data, this.projectId);
                    }
                  }
                })
                .catch(() => {
                  return;
                });
            } else {
              this.$message({
                message: "请选择文件",
                type: "warning",
              });
            }
          })
          .catch(() => {
            return;
          });
      }
    },
    //获取导入进度
    /* /biz/projectUser/importProgress */
    doProgress(batchId, projectId) {
      this.$post(
        "/biz/project/user/v2/import/progress",
        { batchId, projectId },
        5000
      ).then((ret) => {
        if (!ret.data.status) {
          this.progressVal = ret.data.progress;
          setTimeout(() => {
            this.doProgress(batchId, projectId);
          }, 2000);
        } else {
          if (ret.data.message) {
            this.$message({
              type: "error",
              message: ret.data.message,
            });
          } else {
            this.totalNum = ret.data.totalNum;
            this.errorNum = ret.data.errorNum;
            this.correctNum = ret.data.correctNum;
            this.importType = ret.data.importType;
            this.batchId = ret.data.batchId;
            this.progressVal = ret.data.progress;
            this.$message({
              type: "success",
              message: "导入成功",
            });
          }
        }
      });
    },
    //导出错误数据
    doExportError() {
      // console.log(this.batchId);
      if (this.batchId == "" || this.fileKey == "") {
        this.$message({
          message: "还没有导入数据，请先导入文档",
          type: "warning",
        });
      } else {
        this.$post(
          "/biz/project/user/v2/error/export",
          { batchId: this.batchId, projectId: this.projectId },
          5000
        ).then((res) => {
          if (res.status == 0) {
            window.open(res.data);
          }
        });
      }
    },
    doCloseload() {
      this.centerDialogVisible = false;
      this.newvalues = [];
      this.fileKey = "";
      this.fileName = "";
      this.progressVal = 0;
      this.doExportInDisabled = false;
      this.getData();
    },
    doCloseloadlb() {
      this.centerDialogVisiblelb = false;
      this.fileKey = "";
      this.fileName = "";
      this.progressVal = 0;
      this.doExportInDisabled = false;
      this.getData();
    },
    downloadTrainDocument(projectId, userId) {
      let parmar = {
        projectId: projectId,
        userId: userId,
      };
      this.$post("/cert/downloadTrainDocument", parmar).then((res) => {
        if (res.status == 0) {
          this.dialogDownload = true;
          this.$nextTick(() => {
            pdf.embed(res.data, "#pdf-content");
          });
        } else {
          that.$message.warning({ message: res.message, duration: 1500 });
        }
      });
    },
    closeDownloadTrainDocument() {
      this.dialogDownload = false;
    },
    downloadTrainCert(projectId, userId) {
      let parma = {
        projectId: projectId,
        userId: userId,
      };
      this.$post("/cert/downloadCert", parma).then((res) => {
        if (res.status == 0) {
          this.dialogCert = true;
          this.$nextTick(() => {
            pdf.embed(res.data, "#pdf-cert");
          });
        } else {
          that.$message.warning({ message: res.message, duration: 1500 });
        }
      });
    },
    closeDownloadCert() {
      this.dialogCert = false;
    },
    closeDocsDialog() {
      this.docsDialogVisible = false;
      this.$refs["docsFormall"].resetFields();
      for (let key in this.docsFormall) {
        this.docsFormall[key] = false;
      }
    },
    showDocsDialog(raw) {
      this.docsDialogVisible = true;
      this.selectedItem.id = raw.projectId;
      this.selectedItem.name = raw.userName;
      this.selectedItem.userId = raw.userId;
    },
    confirmDocsDialog() {
      let params = {
        fileDTOS: [],
      };
      let examineTypes = [];
      for (let i in this.docsFormall) {
        if (this.docsFormall[i]) {
          examineTypes.push(i);
        }
      }
      if (examineTypes) {
        params = {
          projectId: this.selectedItem.id,
          userId: this.selectedItem.userId,
          examineTypes: examineTypes,
        };
      }
      if (!examineTypes) {
        this.$message.error("请至少选择一项进行打印");
        return;
      }
      // if (!params.fileDTOSAll.length) {
      //   this.$message.error("请至少选择一项进行打印");
      //   return;
      // }

      this.$post("cert/downloadExamineReport", params).then((re) => {
        if (re.status == "0") {
          let data = re.data;
          this.closeDocsDialog();
          this.dialogDocs = true;
          this.$nextTick(() => {
            pdf.embed(data, "#pdf-cert");
          });
        } else {
          this.$message.error(re.message);
        }
      });
    },
    /* 民族 */
    getNationList() {
      this.$post("/sys/dictionary/key", {
        dictKey: "NATION",
      })
        .then((res) => {
          if (res.status == "0") {
            this.nationList = res.data || [];
          }
        })
        .catch(() => {
          return;
        });
    },
    /* 学员类型 */
    getstudengTypeList() {
      this.$post("/sys/dictionary/key", {
        dictKey: "STUDENTTYPE",
      })
        .then((res) => {
          if (res.status == "0") {
            this.studengTypeList = res.data || [];
          }
        })
        .catch(() => {
          return;
        });
    },
    /* 类型 */
    getUserTypeList() {
      this.$post("/sys/dictionary/key", {
        dictKey: "USERTYPE",
      })
        .then((res) => {
          if (res.status == "0") {
            this.UserTypeList = res.data || [];
          }
        })
        .catch(() => {
          return;
        });
    },
    //获取行政区划
    getareatree() {
      this.$post("/sys/area/heNanTree").then((ret) => {
        this.areatreeList = ret.data;
      });
    },
    //人员类别维护
    PersonnelCategory() {
      this.centerDialogVisiblelb = true;
    },
    // 导出已考试且合格人员
    doExportPush() {
      this.$confirm(
        "导出数据仅限于业务范围内使用，不得用于其它用途，如因个人原因导致数据泄露，由个人承担法律责任。",
        "导出提示",
        {
          confirmButtonText: "导出",
          confirmButtonClass: "confirmButtonClass",
          cancelButtonText: "取消",
        }
      )
        .then(() => {
          const params = {
            pageNum: this.pageNum,
            pageSize: this.pageSize,
            projectId: this.projectId,
          };
          this.$post("/biz/projectUser/exportGraduationUser", params).then(
            (res) => {
              if (res.status == "0") {
                window.open(res.message);
              }
            }
          );
        })
        .catch(() => { });
    },
    // 导入已考试且合格人员
    uploadChange1(file) {
      this.fileName = file.name;
      let size = file.size / 1024 / 1024;
      // let fileName = file.name.split(".");
      let extension = file.name.substring(file.name.lastIndexOf(".") + 1);
      const isXLSX = extension === "XLSX";
      const isxlsx = extension === "xlsx";
      const isXLS = extension === "XLS";
      const isxls = extension === "xls";
      if (!isXLSX && !isxlsx && !isXLS && !isxls) {
        this.$message.error("只能上传后缀是.xlsx或者.xls的文件");
        return;
      }
      if (size > 1) {
        this.$message.error("文件大小不能超过1M");
        return;
      }
      let formData = new FormData();
      formData.append("folder ", "USER");
      formData.append("file ", file.raw);
      formData.append("fileType ", extension);

      this.$Postformat("/sys/upload", formData)
        .then((result) => {
          // console.log(result);
          this.fileKey = result.data.fileKey;
          // this.fileName = result.data.fileKey;
        })
        .catch((err) => {
          return;
        });
    },
    doExportIn() {
      if (this.fileKey == "") {
        this.$message({
          message: "请选择文件",
          type: "warning",
        });
      } else {
        this.$confirm("是否将此表中学员人员类别绑定?", "提示", {
          confirmButtonText: "确定",
          confirmButtonClass: "confirmButtonClass",
          cancelButtonText: "取消",
          type: "warning",
        })
          .then(() => {
            if (this.fileKey) {
              const parmar = {
                excelUrl: this.fileKey,
                projectId: this.projectId,
              };
              this.$post("/biz/sou/cc/importExcel", parmar, 5000)
                .then((res) => {
                  if (res.status == 0) {
                    this.totalNumlb = res.data.totalNum;
                    this.errorNumlb = res.data.errorNum;
                    this.correctNumlb = res.data.correctNum;
                    this.importType = res.data.importType;
                    this.batchId = res.data.batchId;
                    if (this.errorNumlb == 0) {
                      this.$message({
                        type: "success",
                        message: "导入成功",
                      });
                    }
                  }
                })
                .catch(() => {
                  return;
                });
            } else {
              this.$message({
                message: "请选择文件",
                type: "warning",
              });
            }
          })
          .catch(() => {
            return;
          });
      }
    },
    //导出错误数据
    doExportError1() {
      // console.log(this.batchId);
      if (this.batchId == "" || this.fileKey == "") {
        this.$message({
          message: "还没有导入数据，请先导入文档",
          type: "warning",
        });
      } else {
        this.$post(
          "/biz/projectUser/exportProjectUser",
          { batchId: this.batchId, importType: this.importType },
          5000
        ).then((res) => {
          if (res.status == 0) {
            window.open(res.data);
          }
        });
      }
    },
    // 导入二寸照片 - 打开弹框
    plsczjzp() {
      this.plsczjzptype = true;
    },
    // 导入二寸照片 - 关闭弹框
    gbplsczjzptype(e) {
      this.plsczjzptype = e;
      this.getData(-1);
    },
  },
  watch: {
    "Form.workUnit"(val) {
      if (val.length > 50) {
        this.$message({
          message: "请输入小于50个字的工作单位",
          type: "error",
        });
        this.Form.workUnit = "";
      }
    },
  },
};
</script>
<style lang="less">
// 截图
.cropper-content {
  .cropper {
    width: auto;
    height: 578px;
  }
}

.cropper_tip {
  background-color: #f1f7fd;
  padding: 15px 20px;

  h3 {
    margin-bottom: 15px;
  }

  .tip_index {
    width: 30px;
    height: 30px;
    line-height: 30px;
    text-align: center;
    background-color: #5b6be7;
    color: #fff;
    border-radius: 50%;
    margin-right: 10px;
  }

  .tip_text {
    min-width: 140px;
  }
}

//
.exportdialog {
  .upload-workers {
    height: 30px;

    .el-upload {
      height: 30px !important;
      width: 97px;
      border: none !important;

      .el-button {
        padding: 5px 0;
      }
    }
  }
}

.pic_upload {
  .el-upload {
    border: 0;
    display: block;
    max-height: 50px !important;
    text-align: left;
  }
}

.idPhoto {
  div {
    position: relative;
    display: flex;

    div {
      position: absolute;
      top: 0;
      left: 0;
      width: 100%;
      height: 100%;
      background-color: rgba(0, 0, 0, 0.5);
    }
  }
}

// 图片裁剪
.vueCropper_dialog {
  .el-upload {
    display: inline-block;
    border: 0;
    height: 16px !important;
  }
}
</style>
<style lang="less" scoped>
.listWrap {
  .el-icon-arrow-down {
    font-size: 12px;

    &:before {
      content: "\e6df" !important;
    }
  }

  .el-tabs {
    flex: 1;
    display: flex;
    flex-direction: column;

    .el-tabs__content {
      flex: 1;
    }
  }
}

.Classitem {
  .el-form-item__content {
    display: flex;
  }
}

.el-button.is-disabled,
.el-button.is-disabled:focus,
.el-button.is-disabled:hover {
  background-color: transparent;
}

.confirmButtonClass {
  color: #fff !important;
  background-color: #5c6be8 !important;
  border-color: #5c6be8 !important;
}

.btnBox {
  margin-left: 1.5rem;
  margin-right: 1.5rem;
}

.exportdialog {
  .el-dialog__title {
    font-size: 16px;
  }

  .export-box {
    width: 100%;
    margin-bottom: 20px;

    h1 {
      padding: 0 5px 10px;
      display: flex;
      align-items: flex-end;
      font-size: 40px;
      border-bottom: 1px dashed #ccc;

      span {
        margin-left: 20px;
        font-size: 14px;
      }
    }

    >div {
      padding-left: 30px;
    }

    .el-button.is-disabled,
    .el-button.is-disabled:focus,
    .el-button.is-disabled:hover {
      color: #fff;
      background: #c3c3c3;
      border: none;
    }
  }
}

.docsDialog {
  .el-form-item {
    margin-bottom: 0.5rem;
  }
}

.studentNum {
  margin: 20px 0 10px;

  span {
    margin: 0 10px;
  }
}
</style>
