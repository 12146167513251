import { render, staticRenderFns } from "./gsStudentList.vue?vue&type=template&id=57e4ceea&scoped=true"
import script from "./gsStudentList.vue?vue&type=script&lang=js"
export * from "./gsStudentList.vue?vue&type=script&lang=js"
import style0 from "./gsStudentList.vue?vue&type=style&index=0&id=57e4ceea&prod&lang=less"
import style1 from "./gsStudentList.vue?vue&type=style&index=1&id=57e4ceea&prod&lang=less&scoped=true"


/* normalize component */
import normalizer from "!../../../node_modules/vue-loader/lib/runtime/componentNormalizer.js"
var component = normalizer(
  script,
  render,
  staticRenderFns,
  false,
  null,
  "57e4ceea",
  null
  
)

export default component.exports