<template>
  <!-- 长春 -->
  <div>
    <div
        class="operationControl flexdc operationControlCurr operationControlWidth"
        style="align-items: flex-start;"
      >
       <div class="searchbox" style="padding-left:0.5rem;">
          <div title="姓名" class="searchboxItem ci-full">
            <span class="itemLabel" style="min-width:7rem">姓名:</span>
            <el-input v-model="userName" type="text" size="small" placeholder="请输入姓名" clearable />
          </div>
          <div title="身份证号" class="searchboxItem ci-full">
            <span class="itemLabel" style="min-width:7rem">身份证号:</span>
            <el-input v-model="idcard" type="text" size="small" placeholder="请输入身份证号" clearable />
          </div>
          <div title="手机号" class="searchboxItem ci-full">
            <span class="itemLabel" style="min-width:7rem">手机号:</span>
            <el-input v-model="mobile" type="text" size="small" placeholder="请输入手机号" clearable />
          </div>
        </div>
        <div class="searchbox" style="padding-left:0.5rem; margin-top:10px">
          <div title="学时学习状态" class="searchboxItem ci-full">
            <span class="itemLabel" style="width:7rem">学时学习状态:</span>
            <el-select
              v-model="completeState"
              placeholder="请选择学时学习状态"
              clearable
              size="small"
            >
              <el-option
                v-for="item in complete"
                :key="item.value"
                :label="item.label"
                :value="item.value"
              ></el-option>
            </el-select>
          </div>
          <div title="推送状态" class="searchboxItem ci-full">
            <span class="itemLabel" style="width:7rem">推送状态:</span>
            <el-select
              v-model="sourseState"
              placeholder="请选择推送状态"
              clearable
              size="small"
            >
              <el-option
                v-for="item in sourseList"
                :key="item.value"
                :label="item.label"
                :value="item.value"
              ></el-option>
            </el-select>
          </div>
          <div>
            <el-button type="primary" class="bgc-bv" round @click="getData()">查询</el-button>
            <el-button type="primary" class="bgc-bv" round @click="doExport()">导出</el-button>
            <el-button type="primary" class="bgc-bv" round @click="PersonnelCategory()">人员类别维护</el-button>
          </div>
        </div>
    </div>
    <div class="framePage-scroll">
      <div class="ovy-a">
        <el-table
          ref="multipleTable"
          :data="tableData"
          :height="tableHeight"
          size="small"
          tooltip-effect="dark"
          style="width: 100%"
          :header-cell-style="tableHeader"
          stripe
        
        >
          <el-table-column
            label="序号"
            align="center"
            type="index"
            fixed
            :index="indexMethod"
          />
          <el-table-column
            label="姓名"
            align="center"
            show-overflow-tooltip
            prop="sourceUserName"
            fixed
          />
          <el-table-column
            label="身份证号"
            align="center"
            show-overflow-tooltip
            prop="sourceUserIdcard"
            width="150px"
          />
          <el-table-column
            label="手机号"
            align="center"
            show-overflow-tooltip
            prop="sourceUserMobile"
          />
          <el-table-column
            label="实名认证"
            align="center"
            show-overflow-tooltip
          >
            <template slot-scope="scope">
              {{ $setDictionary("AUTHENTICATION", scope.row.authentication) }}
            </template>
          </el-table-column>
          <el-table-column
            label="要求总学时"
            align="center"
            show-overflow-tooltip
            prop="totalRequireLessonNum"
          />
          <el-table-column label="已完成学时" align="center">
            <template slot-scope="scope">
              {{ scope.row.totalStudyLessonNum }}
            </template>
          </el-table-column>
          <el-table-column label="学时学习状态" align="center" minWidth="120">
            <template slot-scope="scope">
              {{ $setDictionary("LEARNINGSTATE", scope.row.completeState) }}
            </template>
          </el-table-column>
           <el-table-column
            label="操作人"
            align="center"
            show-overflow-tooltip
            prop="fullname"
          />
          <el-table-column
            label="课程考试分数"
            align="center"
            minWidth="120"
            show-overflow-tooltip
            prop="paperScore"
          >
          </el-table-column>
          <el-table-column
            label="人员类别"
            align="center"
            minWidth="150"
            show-overflow-tooltip
            prop="ccUserType"
          >
            <template slot-scope="scope">
              {{ $setDictionary("CCUSERTYPE", scope.row.ccUserType) || '--'}}
            </template>
          </el-table-column>
          <el-table-column
            label="推送状态"
            align="center"
            minWidth="120"
            show-overflow-tooltip
            prop="sourceUserState"
          >
            <template slot-scope="scope">
              {{ $setDictionary("SOURCEUSERSTATE", scope.row.sourceUserState) }}
            </template>
          </el-table-column>
          <el-table-column
            label="描述"
            align="center"
            show-overflow-tooltip
            prop="sourceUserError"
          />
          <el-table-column label="操作" align="center" width="320px">
            <div slot-scope="scope" class="flexcc">
              <el-button
                type="text"
                size="mini"
                style="padding:0 5px"
                :disabled="!scope.row.projectUserCourseId"
                @click="lookstudent(scope.row.projectUserCourseId)"
                >查看</el-button
              >
              <el-button
                v-if="scope.row.auditState !== '30'"
                type="text"
                size="mini"
                style="padding:0 5px"
                :disabled="!scope.row.projectUserCourseId"
                @click="Learningrecords(scope.row)"
                >学习记录</el-button
              >
              <el-button
                type="text"
                size="mini"
                style="padding:0 5px"
                :disabled="!scope.row.canPrint"
                @click="
                  downloadTrainDocument(scope.row.projectId, scope.row.userId)
                "
                >打印培训档案</el-button
              >
              <el-button
                type="text"
                size="mini"
                style="padding:0 5px"
                :disabled="!scope.row.canPrint"
                @click="
                  downloadTrainCert(scope.row.projectId, scope.row.userId)
                "
                >打印学时证明</el-button
              >
            </div>
          </el-table-column>
          <Empty slot="empty" />
        </el-table>
      </div>
    </div>
    <PageNum :apiData="apiData" @sizeChange="sizeChange" @getData="getData" />
    <el-dialog
      :title="dialogTitle"
      :visible.sync="dialogVisible"
      width="50%"
      top="2%"
      :center="true"
      :before-close="doClose"
    >
      <el-form label-width="120px" ref="Form" :model="Form">
        <el-form-item label="学员姓名" prop="studentname">
          <el-input
            v-model="Form.studentname"
            type="text"
            size="small"
            :disabled="disabled"
          />
        </el-form-item>
        <el-form-item label="身份证号" required>
          <el-input
            v-model="Form.idCard"
            type="text"
            size="small"
            :disabled="disabled"
          />
        </el-form-item>
        <el-form-item label="性别" required>
          <el-radio-group v-model="Form.sex" disabled>
            <el-radio label="1">男</el-radio>
            <el-radio label="2">女</el-radio>
          </el-radio-group>
        </el-form-item>
        <el-form-item label="联系电话" required class="Classitem">
          <el-input
            v-model="Form.mobile"
            type="text"
            size="small"
            :disabled="disabled"
          />
        </el-form-item>
        <el-form-item label="电子邮件" required>
          <el-input
            v-model="Form.email"
            type="text"
            size="small"
            :disabled="disabled"
          />
        </el-form-item>
        <el-form-item label="所属公司" required>
           <el-input
            v-model="Form.company"
            type="text"
            size="small"
            :disabled="disabled"
          />
          <!-- <el-select
            v-model="Form.compId"
            :disabled="disabled"
            size="small"
          >
            <el-option
              v-for="item in companyList || []"
              :key="(item || {}).compId"
              :label="(item || {}).compName"
              :value="(item || {}).compId"
            ></el-option>
          </el-select> -->
        </el-form-item>
        <el-form-item label="文化水平" required>
          <el-select
            v-model="Form.educationLevel"
            placeholder="请选择文化水平"
            size="small"
            :disabled="disabled"
          >
            <el-option
              v-for="item in educationList"
              :key="item.value"
              :label="item.label"
              :value="item.value"
            ></el-option>
          </el-select>
        </el-form-item>
      </el-form>
    </el-dialog>
    <el-dialog
      title="下载培训档案"
      :visible.sync="dialogDownload"
      width="50%"
      top="2%"
      center
      :before-close="closeDownloadTrainDocument"
    >
      <div id="pdf-content" style="height: 600px"></div>
    </el-dialog>
    <el-dialog
      title="下载学时证明"
      :visible.sync="dialogCert"
      width="50%"
      top="2%"
      center
      :before-close="closeDownloadCert"
    >
      <div id="pdf-cert" style="height: 600px"></div>
    </el-dialog>
      <el-dialog
      :title="projectName + '人员类别维护'"
      :visible.sync="centerDialogVisible"
      width="50%"
      center
      :before-close="doCloseload"
      class="exportdialog"
    >
      <div class="flexdcc">
        <div class="export-box">
          <h1>
            1
            <span>下载本班已完成推送学员</span>
          </h1>
          <div class="df">
            <span
              @click="doExportPush"
             v-show="projectSource != '40' && trainTypeId != '11'"
              style="
                margin-top: 20px;
                margin-right: 20px;
                color: #409eff;
                cursor: pointer;
              "
              >已推送学员</span
            >
            <!-- <span
              @click="doExportPush"
              v-show="areaId != '220100'"
              style="
                margin-top: 20px;
                margin-right: 20px;
                color: #409eff;
                cursor: pointer;
              "
              >已考试且合格人员</span
            > -->
          </div>
        </div>
        <div class="export-box">
          <h1>
            2
            <span>导入补充完人员类别的Excel文档</span>
          </h1>
          <div class="flexdc">
            <div class="df" style="margin: 20px 0 10px">
              <el-upload
                class="upload-demo upload-workers"
                :action="actionUrl"
                :on-error="handleError"
                :on-success="handleSuccess"
                :on-change="uploadChange"
                :show-file-list="false"
                :auto-upload="false"
              >
                <el-button class="bgc-bv" style="font-size: 12px; width: 80%"
                  >浏览</el-button
                >
              </el-upload>
              <p v-if="fileName" style="margin-top: 10px; margin-left: 10px">
                当前选择文件：
                <span style="color: #f46173">{{ fileName }}</span>
              </p>
              <p v-else style="margin-top: 10px; margin-left: 10px">
                未选择文件
              </p>
            </div>
            <div>
              <el-button
                class="bgc-bv"
                size="mini"
                style="margin-top: 10px; height: 35px"
                @click="doExportIn"
                >开始导入</el-button
              >
            </div>
          </div>
        </div>
        <div class="export-box">
          <h1>
            3
            <span>导入结果</span>
          </h1>
          <div class="df studentNum">
            <span>学员总数量:{{ totalNum }}人;</span>
            <span>成功:{{ correctNum }}人;</span>
            <span>失败:{{ errorNum }}人;</span>
          </div>
          <div>
            <el-button
              class="bgc-bv"
              style="margin-top: 15px; height: 35px"
              size="mini"
              :disabled="errorNum == '0'"
               @click="doExportError"
              >导出错误数据</el-button
            >
          </div>
          <div style="margin-top: 15px; color: #dd1d35" v-if="errorNum != 0">
            有导入失败学员，请导出错误数据，将导入失败学员调整后重新上传
          </div>
        </div>
      </div>
    </el-dialog>
  </div>
</template>
<script>
import Empty from "@/components/Empty.vue";
import PageNum from "@/components/PageNum.vue";
import List from "@/mixins/List";
import { mapGetters } from "vuex";
import pdf from "pdfobject";
export default {
  name: "myresourseList",
  components: {
    Empty,
    PageNum,
  },
  props: ["length"],
  mixins: [List],
  data() {
    return {
      dialogTitle: "",
      disabled: true,
      dialogVisible: false,
      userJson: {},
      userName: "",
      idcard:'',
      mobile: "",
      completeState: "",
      complete: [],
      sourseState: "",
      sourseList: [],
      educationList: [],
      companyList: [],
      Form: {
        studentname: "",
        idCard: "",
        sex: "",
        mobile: "",
        email: "",
        company: "",
        compId: "",
        educationLevel: "",
      },
      activeName: "first",
      projectUserCourseId: "",
      dialogDownload: false,
      dialogCert: false,
      projectName: "",
      courseCount: 0,
      centerDialogVisible:false,
      fileName:'',
      fileKey:'',
        totalNum: "0",
      errorNum: "0",
      correctNum: "0",
      batchId: "",
      importType: "",
      areaId:'',
    };
  },
  created() {
    this.getTableHeight();
    const query = this.$route.query;
    if (query.active == "three") {
      this.openOnce();
    }
  },
  methods: {
    init() {
      this.projectId = sessionStorage.getItem("projectId");
      this.areaId = sessionStorage.getItem("areaId");
      this.projectName = sessionStorage.getItem("projectName");
      this.courseCount = sessionStorage.getItem("courseCount");
      
      this.getcompleteList();
      this.getData(-1);
    },
      // 每天弹一次  别删
    openOnce() {
      //每天首次进入的时候弹提示--------- 开始
      let userId = JSON.parse(sessionStorage.getItem("userJson")).userId;
      let user = JSON.parse(localStorage.getItem(userId)) || {};
      //获取当前时间
      let date = new Date();
      let year = date.getFullYear();
      let month = date.getMonth() + 1;
      let day = date.getDate();
      if (month < 10) {
        month = "0" + month;
      }
      if (day < 10) {
        day = "0" + day;
      }
      let nowDate = year + "-" + month + "-" + day;
      // console.log(localStorage.getItem(userId));

      if (!localStorage.getItem(userId)) {
        console.log("1");
        this.$confirm(
          "根据有关部门业务开展需要，相关数据会涉及个人信息、照片等敏感信息，导出数据仅限于业务范围内使用，不得用于其他用途，如因个人原因导致数据泄露，由个人承担法律责任。",
          "提示",
          {
            showClose: false, //是否显示右上角关闭按钮
            showCancelButton: false, //是否显示取消按钮
            closeOnClickModal: false, //是否可通过点击遮罩关闭 MessageBox
            confirmButtonText: "同意并继续",
            confirmButtonClass: "bgc-bv",
          }
        )
          .then(() => {
            let userKey = {};
            userKey["StudentList"] = nowDate;
            localStorage.setItem(userId, JSON.stringify(userKey));
          })
          .catch(() => {});
      } else if (user.StudentList == undefined || user.StudentList < nowDate) {
        console.log("2");
        this.$confirm(
          "根据有关部门业务开展需要，相关数据会涉及个人信息、照片等敏感信息，导出数据仅限于业务范围内使用，不得用于其他用途，如因个人原因导致数据泄露，由个人承担法律责任。",
          "提示",
          {
            showClose: false, //是否显示右上角关闭按钮
            showCancelButton: false, //是否显示取消按钮
            closeOnClickModal: false, //是否可通过点击遮罩关闭 MessageBox
            confirmButtonText: "同意并继续",
            confirmButtonClass: "bgc-bv",
          }
        )
          .then(() => {
            let userKey = JSON.parse(localStorage.getItem(userId));
            userKey.StudentList = nowDate;
            localStorage.setItem(userId, JSON.stringify(userKey));
          })
          .catch(() => {});
      }
      //每天首次进入的时候弹提示-------- 结束
    },
    // 获取学习状态&& 学历
    getcompleteList() {
      const studylist = this.$setDictionary("LEARNINGSTATE", "list");
      const educationList = this.$setDictionary("EDUCATION", "list");
      const sourseList = this.$setDictionary("SOURCEUSERSTATE", "list");
      const list = [];
      const eduList = [];
      const sourseLists = [];
      for (const key in studylist) {
        list.push({
          value: key,
          label: studylist[key],
        });
      }
      for (const key in educationList) {
        eduList.push({
          value: key,
          label: educationList[key],
        });
      }
      for (const key in sourseList) {
        sourseLists.push({
          value: key,
          label: sourseList[key],
        });
      }
      this.educationList = eduList;
      this.complete = list;
      this.sourseList = sourseLists;
    },
    //列表数据
    getData(pageNum) {
      const params = {
        pageNum: pageNum,
        pageSize: this.pageSize,
        projectId: this.projectId,
      };
      if (this.userName) {
        params.username = this.userName;
      }
      if (this.idcard) {
        params.idCard = this.idcard;
      }
      if (this.mobile) {
        params.mobile = this.mobile;
      }
      if (this.completeState) {
        params.completeState = this.completeState;
      }
      if (this.sourseState) {
        params.status = this.sourseState;
      }
      this.doFetch({
        url: "/biz/sou/cc/studentPage",
        params,
        pageNum,
      });
    },
    Learningrecords(row) {
      sessionStorage.setItem("row", JSON.stringify(row));
      this.$router.push({
        path: "/web/Learningrecords",
      });
    },
    doClose(done) {
      done();
    },
    lookstudent(projectUserCourseId) {
      this.$post("/biz/projectUser/queryCourseUser", { projectUserCourseId })
        .then((ret) => {
          let records = ret.data || {};
          this.Form.studentname = records.userName;
          this.Form.idCard = records.idcard;
          this.Form.sex = records.sex;
          this.Form.mobile = records.mobile;
          this.Form.email = records.email || '无';
          this.Form.company = records.compName || '无';
          this.Form.compId = records.compId || "";
          this.Form.educationLevel = records.educationLevel || '无';
          this.Form.userId = records.userId || "";
          this.dialogTitle = "查看学员信息";
          this.dialogVisible = true;
        })
        .catch((err) => {
          return;
        });
    },
    // 导出
    doExport() {
        this.$confirm('导出数据仅限于业务范围内使用，不得用于其它用途，如因个人原因导致数据泄露，由个人承担法律责任。', '导出提示', {
          confirmButtonText: '导出',
           confirmButtonClass: "confirmButtonClass",
          cancelButtonText: '取消',
        }).then(() => {
              const params = {
            pageNum: this.pageNum,
            pageSize: this.pageSize,
            projectId: this.projectId,
          };
           if (this.userName) {
        params.username = this.userName;
      }
      if (this.idcard) {
        params.idCard = this.idcard;
      }
      if (this.mobile) {
        params.mobile = this.mobile;
      }
      if (this.completeState) {
        params.completeState = this.completeState;
      }
      if (this.sourseState) {
        params.status = this.sourseState;
      }
          this.$post("/biz/sou/cc/studentExport", params).then((res) => {
            if (res.status == "0") {
              window.open(res.data);
            }
          });
        }).catch(() => {         
        });
      
    },
    // 导出已推送学员
    doExportPush() {
        this.$confirm('导出数据仅限于业务范围内使用，不得用于其它用途，如因个人原因导致数据泄露，由个人承担法律责任。', '导出提示', {
          confirmButtonText: '导出',
           confirmButtonClass: "confirmButtonClass",
          cancelButtonText: '取消',
        }).then(() => {
              const params = {
            pageNum: this.pageNum,
            pageSize: this.pageSize,
            projectId: this.projectId,
          };
          this.$post("/biz/sou/cc/downloadCompletePushedUser", params).then((res) => {
            if (res.status == "0") {
              window.open(res.message);
            }
          });
        }).catch(() => {         
        });
      
    },
    // 导出已考试且合格人员
    doExportPush2() {
        this.$confirm('导出数据仅限于业务范围内使用，不得用于其它用途，如因个人原因导致数据泄露，由个人承担法律责任。', '导出提示', {
          confirmButtonText: '导出',
           confirmButtonClass: "confirmButtonClass",
          cancelButtonText: '取消',
        }).then(() => {
              const params = {
            pageNum: this.pageNum,
            pageSize: this.pageSize,
            projectId: this.projectId,
          };
          this.$post("/biz/projectUser/exportGraduationUser", params).then((res) => {
            if (res.status == "0") {
              window.open(res.message);
            }
          });
        }).catch(() => {         
        });
      
    },
    downloadTrainDocument(projectId, userId) {
      let parmar = {
        projectId: projectId,
        userId: userId,
      };
      this.$post("/cert/downloadTrainDocument", parmar).then((res) => {
        if (res.status == 0) {
          this.dialogDownload = true;
          this.$nextTick(() => {
            pdf.embed(res.data, "#pdf-content");
          });
        } else {
          that.$message.warning({ message: res.message, duration: 1500 });
        }
      });
    },
    closeDownloadTrainDocument() {
      this.dialogDownload = false;
    },
    downloadTrainCert(projectId, userId) {
      let parma = {
        projectId: projectId,
        userId: userId,
      };
      this.$post("/cert/downloadCert", parma).then((res) => {
        if (res.status == 0) {
          this.dialogCert = true;
          this.$nextTick(() => {
            pdf.embed(res.data, "#pdf-cert");
          });
        } else {
          that.$message.warning({ message: res.message, duration: 1500 });
        }
      });
    },
    closeDownloadCert() {
      this.dialogCert = false;
    },
    //人员类型维护
    PersonnelCategory() {
      this.centerDialogVisible = true
    },
     // 导入学员
    uploadChange(file) {
      this.fileName = file.name;
      let size = file.size / 1024 / 1024;
      // let fileName = file.name.split(".");
      let extension = file.name.substring(file.name.lastIndexOf(".") + 1);
      const isXLSX = extension === "XLSX";
      const isxlsx = extension === "xlsx";
      const isXLS = extension === "XLS";
      const isxls = extension === "xls";
      if (!isXLSX && !isxlsx && !isXLS && !isxls) {
        this.$message.error("只能上传后缀是.xlsx或者.xls的文件");
        return;
      }
      if (size > 1) {
        this.$message.error("文件大小不能超过1M");
        return;
      }
      let formData = new FormData();
      formData.append("folder ", "USER");
      formData.append("file ", file.raw);
      formData.append("fileType ", extension);

      this.$Postformat("/sys/upload", formData)
        .then((result) => {
          // console.log(result);
          this.fileKey = result.data.fileKey;
          // this.fileName = result.data.fileKey;
        })
        .catch((err) => {
          return;
        });
    },
    doExportIn() {
      if (this.fileKey == "") {
        this.$message({
          message: "请选择文件",
          type: "warning",
        });
      } else {
        this.$confirm("是否将此表中学员人员类别绑定?", "提示", {
          confirmButtonText: "确定",
          confirmButtonClass: "confirmButtonClass",
          cancelButtonText: "取消",
          type: "warning",
        })
          .then(() => {
            if (this.fileKey) {
              const parmar = {
                excelUrl: this.fileKey,
              };
              this.$post("/biz/sou/cc/importExcel", parmar, 5000)
                .then((res) => {
                  if (res.status == 0) {
                    this.totalNum = res.data.totalNum;
                    this.errorNum = res.data.errorNum;
                    this.correctNum = res.data.correctNum;
                    this.importType = res.data.importType;
                    this.batchId = res.data.batchId;
                    this.$message({
                      type: "success",
                      message: "导入成功",
                    });
                  }
                })
                .catch(() => {
                  return;
                });
            } else {
              this.$message({
                message: "请选择文件",
                type: "warning",
              });
            }
          })
          .catch(() => {
            return;
          });
      }
    },
    //导出错误数据
    doExportError() {
      // console.log(this.batchId);
      if (this.batchId == "" || this.fileKey == "") {
        this.$message({
          message: "还没有导入数据，请先导入文档",
          type: "warning",
        });
      } else {
        this.$post(
          "/biz/projectUser/exportProjectUser",
          { batchId: this.batchId, importType: this.importType },
          5000
        ).then((res) => {
          if (res.status == 0) {
            window.open(res.data);
          }
        });
      }
    },
    doCloseload() {
      this.centerDialogVisible = false;
      this.newvalues = [];
      this.fileKey = "";
      this.fileName = "";
      this.getData();
    },
    getTableHeight(opDom = true, page = true) {
      let tHeight =
        window.innerHeight - (3 + 1.35 + 3.25 + 0.675 + 2 + 1.35 +5.5) * 16;
      if (opDom) {
        tHeight -=  40 + 0.675 * 16 + 1;
      }
      if (page) {
        tHeight -= 32;
      }
      this.tableHeight = tHeight;
    }
  },
   
};
</script>
<style lang="less">
.upload-workers {
  height: 40px;
  .el-upload {
    height: 40px !important;
    border: none !important;
  }
}
</style>
<style lang="less" scoped>
.listWrap {
  .el-icon-arrow-down {
    font-size: 12px;
    &:before {
      content: "\e6df" !important;
    }
  }
  .el-tabs {
    flex: 1;
    display: flex;
    flex-direction: column;
    .el-tabs__content {
      flex: 1;
    }
  }
}
.Classitem {
  .el-form-item__content {
    display: flex;
  }
}
// .el-button.is-disabled,
// .el-button.is-disabled:focus,
// .el-button.is-disabled:hover {
//   background-color: transparent;
// }

.confirmButtonClass {
  color: #fff !important;
  background-color: #5c6be8 !important;
  border-color: #5c6be8 !important;
}
.btnBox {
  margin-left: 1.5rem;
  margin-right: 1.5rem;
}
.exportdialog {
  .el-dialog__title {
    font-size: 16px;
  }
  .export-box {
    width: 100%;
    margin-bottom: 20px;
    h1 {
      padding: 0 5px 10px;
      display: flex;
      align-items: flex-end;
      font-size: 40px;
      border-bottom: 1px dashed #ccc;
      span {
        margin-left: 20px;
        font-size: 14px;
      }
    }
    > div {
      padding-left: 30px;
    }
  }
}
</style>
