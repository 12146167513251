<template>
  <!-- 导入二寸照片 -->
  <el-dialog
    title="导入二寸照片"
    :visible.sync="uploadfiledialog"
    width="50%"
    class="exportdialog"
    @close="uploadfileClose"
  >
    <div class="flexdcc">
      <div class="export-box">
        <h1>1<span>导入须知</span></h1>
        <div style="color: red; padding-top: 5px">
          <p>温馨提示，请在导入前仔细阅读以下提示信息:</p>
          <p>1.导入的文件格式为zip压缩包；</p>
          <p>
            2.需要导入的二寸证件照片以18位身份证号命名，照片格式为png或jpg，例如：110100100001010011.jpg；
          </p>
          <p>
            3.将需要导入的证件照片选中后直接压缩，不要将照片放在文件夹内再压缩文件夹;
          </p>
          <p>
            4.单个压缩包大小不可超过20M，超过20M后，每20M左右一个压缩包，然后分别导入即可。
          </p>
        </div>
      </div>
      <div class="export-box">
        <h1>2<span>导入文件</span></h1>
        <div class="flexdc">
          <div class="df" style="margin: 20px 0 10px">
            <el-upload
              class="upload-demo upload-workers"
              :action="actionUrl"
              :on-error="handleError"
              :on-success="handleSuccess"
              :on-change="uploadChange"
              :show-file-list="false"
              :auto-upload="false"
            >
              <el-button class="bgc-bv" style="font-size: 12px; width: 80%"
                >浏览</el-button
              >
            </el-upload>
            <p v-if="fileName" style="margin-top: 10px; margin-left: 10px">
              当前选择文件：
              <span style="color: #f46173">{{ fileName }}</span>
            </p>
            <p v-else style="margin-top: 10px; margin-left: 10px">
              未选择文件
            </p>
          </div>
          <div>
            <el-button
              class="bgc-bv"
              size="mini"
              style="margin-top: 10px; height: 35px"
              :disabled="!fileKey"
              @click="doExport"
              >开始导入</el-button
            >
          </div>
          <el-progress
            v-if="Number(progressVal) > 0 && Number(progressVal) < 100"
            style="margin-top: 10px;"
            :text-inside="true"
            :stroke-width="16"
            :percentage="progressVal"
          ></el-progress>
        </div>
      </div>
      <div class="export-box">
        <h1>
          3
          <span>导入结果</span>
        </h1>
        <div class="df studentNum">
          <span>学员总数量:{{ totalNum }}人;</span>
          <span>成功:{{ correctNum }}人;</span>
          <span>失败:{{ errorNum }}人;</span>
        </div>
        <div>
          <el-button
            class="bgc-bv"
            style="margin-top: 15px; height: 35px"
            size="mini"
            :disabled="!excelUrl"
            @click="doExportError"
            >导出错误数据</el-button
          >
        </div>
        <div style="margin-top: 15px; color: #dd1d35" v-if="errorNum != 0">
          有导入失败学员，请导出错误数据，将导入失败学员调整后重新上传
        </div>
      </div>
    </div>
  </el-dialog>
</template>
<script>
import { console } from "../utils/helper";

export default {
  name: "batchStudentsUploadIdPhotos",
  components: {},
  props: ["tk"],
  data() {
    return {
      // 上传附件弹框
      uploadfiledialog: false,
      // 导入文件的key
      fileKey: "",
      // 文件名
      fileName: "",
      // 进度条百分比
      progressVal: 0,
      // 导入 - 学员总数量
      totalNum: "",
      // 导入 - 学员失败数量
      errorNum: "",
      // 导入 - 学员成功数量
      correctNum: "",
      // 导出 - 错误的url地址
      excelUrl: "",
    };
  },
  computed: {},
  created() {},
  mounted() {},
  watch: {
    tk: function(val) {
      this.uploadfiledialog = val;
    },
  },
  methods: {
    // 上传文件
    uploadChange(file) {
      this.fileName = file.name;
      let size = file.size / 1024 / 1024;
      let extension = file.name.substring(file.name.lastIndexOf(".") + 1);
      const isXLSX = extension === "zip";
      if (!isXLSX) {
        this.$message.error("只能上传后缀是.zip的文件");
        return;
      }
      if (size > 20) {
        this.$message.error("文件大小不能超过20M");
        return;
      }
      let formData = new FormData();
      formData.append("folder ", "USER");
      formData.append("file ", file.raw);
      formData.append("fileType ", extension);
      this.$Postformat("/sys/upload", formData)
        .then((result) => {
          this.fileKey = result.data.fileKey;
        })
        .catch((err) => {
          return;
        });
    },
    // 开始导入
    doExport() {
      if (this.fileKey) {
        this.doExportInDisabled = true;
        this.$post("/biz/importPhoto/dispatch", {
          ossKey: this.fileKey,
        })
          .then((res) => {
            ;
            this.doProgress(res.data);
          })
          .catch(() => {
            return;
          });
      } else {
        this.$message({
          message: "请选择文件",
          type: "warning",
        });
      }
    },
    // 实时获取进度条
    doProgress(id) {
      ;
      this.$post("/biz/importPhoto/import/progress", { batchId: id }).then(
        (ret) => {
          console.log(ret);
          if (!ret.data.status) {
            this.progressVal = ret.data.progress;
            setTimeout(() => {
              this.doProgress(batchId, projectId);
            }, 1000);
          } else {
            this.totalNum = ret.data.totalNum;
            this.errorNum = ret.data.errorNum;
            this.correctNum = ret.data.correctNum;
            this.progressVal = ret.data.progress;
            this.excelUrl = ret.data.excelUrl;
            if (ret.data.excelUrl) {
              this.$message({
                type: "error",
                message: ret.data.message,
              });
            } else {
              this.$message({
                type: "success",
                message: "导入成功",
              });
            }
          }
        }
      );
    },
    // 导出错误数据
    doExportError() {
      window.open(this.excelUrl);
    },
    // 关闭弹框
    uploadfileClose() {
      this.uploadfiledialog = false;
      this.fileKey = "";
      this.fileName = "";
      this.totalNum = "";
      this.errorNum = "";
      this.correctNum = "";
      this.progressVal = 0;
      this.excelUrl = "";
      this.$emit("cgbplsczjzptype", false);
    },
  },
};
</script>
<style lang="less" scoped>
.exportdialog {
  .el-dialog__title {
    font-size: 16px;
  }
  .export-box {
    width: 100%;
    margin-bottom: 20px;
    h1 {
      padding: 0 5px 10px;
      display: flex;
      align-items: flex-end;
      font-size: 40px;
      border-bottom: 1px dashed #ccc;
      span {
        margin-left: 20px;
        font-size: 14px;
      }
    }
    > div {
      padding-left: 30px;
    }
    .el-button.is-disabled,
    .el-button.is-disabled:focus,
    .el-button.is-disabled:hover {
      color: #fff;
      background: #c3c3c3;
      border: none;
    }
  }
}
</style>
