<template>
  <!-- 山西 -->
  <div>
    <div
      class="operationControl flexdc operationControlCurr operationControlWidth"
      style="align-items: flex-start;"
    >
      <div class="searchbox" style="padding-left:0.5rem;">
        <div title="姓名" class="searchboxItem ci-full">
          <span class="itemLabel">姓名:</span>
          <el-input
            v-model="userName"
            type="text"
            size="small"
            placeholder="请输入姓名"
            clearable
          />
        </div>
        <div title="身份证号" class="searchboxItem ci-full">
          <span class="itemLabel">身份证号:</span>
          <el-input
            v-model="idcard"
            type="text"
            size="small"
            placeholder="请输入身份证号"
            clearable
          />
        </div>
        <div title="手机号" class="searchboxItem ci-full">
          <span class="itemLabel phoneitemLabel">手机号:</span>
          <el-input
            v-model="mobile"
            type="text"
            size="small"
            placeholder="请输入手机号"
            clearable
          />
        </div>
        <div title="领券时间" class="searchboxItem ci-full">
          <span class="itemLabel">领券时间:</span>
          <el-date-picker
            v-model="shanxiUserStart"
            type="daterange"
            size="small"
            range-separator="至"
            value-format="timestamp"
          >
          </el-date-picker>
        </div>
      </div>
      <div class="searchbox" style="padding-left:0.5rem; margin-top:10px;">
        <div title="学习状态" class="searchboxItem ci-full itemLabel_study">
          <span class="itemLabel">学习状态:</span>
          <el-select
            v-model="completeState"
            placeholder="请选择学习状态"
            clearable
            size="small"
          >
            <el-option
              v-for="item in complete"
              :key="item.value"
              :label="item.label"
              :value="item.value"
            ></el-option>
          </el-select>
        </div>
        <div title="电子券编号" class="searchboxItem ci-full">
          <span class="itemLabel">电子券编号:</span>
          <el-input
            v-model="shanxiCouponCode"
            type="text"
            size="small"
            placeholder="请输入电子券编号"
            clearable
          />
        </div>
        <div title="单元测试总正确率" class="searchboxItem ci-full">
          <span class="itemLabel">总正确率:</span>
          <el-input-number
            v-model="ratioStart"
            :controls="false"
            style="width: 3.5rem;"
            :min="0"
            :max="ratioEnd !== undefined ? ratioEnd : 100"
          ></el-input-number>
          <span style="min-width: 3rem">%至</span>
          <el-input-number
            v-model="ratioEnd"
            :controls="false"
            style="width: 3.5rem;"
            :min="ratioStart !== undefined ? ratioStart : 0"
            :max="100"
          ></el-input-number>
          <span style="min-width: 2rem">%</span>
        </div>
        <div title="学习截止时间" class="searchboxItem ci-full">
          <span class="itemLabel" style="margin-right: 8px"
            >学习截止时间:
          </span>
          <el-date-picker
            v-model="shanxiUserEnd"
            type="daterange"
            size="small"
            range-separator="至"
            value-format="timestamp"
          >
          </el-date-picker>
        </div>
      </div>
       <div class="searchbox" style="padding-left:0.5rem; margin-top:10px;">
        <div title="推送状态" class="searchboxItem ci-full itemLabel_study">
          <span class="itemLabel">推送状态:</span>
          <el-select
            v-model="shanxiUserPushState"
            placeholder="请选择推送状态"
            clearable
            size="small"
          >
            <el-option
              v-for="item in pushStatus"
              :key="item.value"
              :label="item.label"
              :value="item.value"
            ></el-option>
          </el-select>
        </div>
        <div>
          <el-button type="primary" class="bgc-bv" round @click="getData()"
            >查询</el-button
          >
           <el-button
            type="primary"
            class="bgc-bv"
            round
            @click="doExport()"
            style="padding: 0 20px"
            >导出补贴花名册</el-button
          >
        </div>
      </div>
    </div>
    <div class="framePage-scroll">
      <div class="ovy-a">
        <el-table
          ref="multipleTable"
          :data="tableData"
          :height="tableHeight"
          size="small"
          tooltip-effect="dark"
          style="width: 100%"
          :header-cell-style="tableHeader"
          stripe
        >
          <el-table-column
            label="序号"
            align="center"
            type="index"
            fixed
            :index="indexMethod"
          />
          <el-table-column
            label="姓名"
            align="center"
            show-overflow-tooltip
            prop="userName"
            fixed
          />
          <el-table-column
            label="身份证号"
            align="center"
            show-overflow-tooltip
            prop="idcard"
            width="150"
          />
          <el-table-column
            label="手机号"
            align="center"
            show-overflow-tooltip
            prop="mobile"
            width="120"
          />
          <el-table-column
            label="实名认证"
            align="center"
            show-overflow-tooltip
          >
            <template slot-scope="scope">
              {{ $setDictionary("AUTHENTICATION", scope.row.authentication) }}
            </template>
          </el-table-column>
          <el-table-column
            label="要求总学时"
            align="center"
            show-overflow-tooltip
            prop="lessonNumDemand"
          />
          <el-table-column label="已完成学时" align="center">
            <template slot-scope="scope">
              {{ scope.row.studyLessonNum }}
            </template>
          </el-table-column>
          <el-table-column label="学习状态" align="center" minWidth="100">
            <template slot-scope="scope">
              {{ $setDictionary("LEARNINGSTATE", scope.row.completeState) }}
            </template>
          </el-table-column>
          <el-table-column
            label="领券时间"
            align="center"
            show-overflow-tooltip
            prop="shanxiUserStart"
            width="130"
            :formatter="shanxiUser"
          />
          <el-table-column
            label="学习截止时间"
            align="center"
            show-overflow-tooltip
            prop="shanxiUserEnd"
            width="130"
            :formatter="shanxiUser"
          />
          <el-table-column
            label="电子券编号"
            align="center"
            show-overflow-tooltip
            prop="shanxiCouponCode"
            width="230"
          />
          <el-table-column
            label="单元测试总正确率"
            align="center"
            show-overflow-tooltip
            prop="ratio"
            width="130"
            :formatter="getratio"
          />
          <el-table-column
            label="推送状态"
            align="center"
            show-overflow-tooltip
            prop="shanxiUserPushState"
            width="100"
          >
          <template slot-scope="scope">
            {{scope.row.shanxiUserPushState ? '已推送':'未推送'}}
          </template>
          </el-table-column>
          <el-table-column label="操作" align="center" width="380px">
            <div slot-scope="scope" class="flexcc">
              <el-button
                type="text"
                size="mini"
                style="padding:0 5px"
                :disabled="!scope.row.projectUserCourseId"
                @click="lookstudent(scope.row.projectUserCourseId)"
                >查看</el-button
              >
              <el-button
                v-if="scope.row.auditState !== '30'"
                type="text"
                size="mini"
                style="padding:0 5px"
                :disabled="!scope.row.projectUserCourseId"
                @click="Learningrecords(scope.row)"
                >学习记录</el-button
              >
              <el-button
                type="text"
                size="mini"
                style="padding:0 5px"
                :disabled="!scope.row.canPrint"
                @click="
                  downloadTrainDocument(scope.row.projectId, scope.row.userId)
                "
                >打印培训档案</el-button
              >
              <el-button
                type="text"
                size="mini"
                style="padding:0 5px"
                :disabled="!scope.row.canPrint"
                @click="
                  downloadTrainCert(scope.row.projectId, scope.row.userId)
                "
                >打印学时证明</el-button
              >
              <!-- <el-button
                type="text"
                size="mini"
                style="padding:0 5px"
                :disabled="scope.row.completeState != 30||!scope.row.unitTestState||scope.row.shanxiUserPushState"
                @click="pushLearningProgress(scope.row.shanxiUserId)"
                >推送学习记录</el-button> -->
            </div>
          </el-table-column>
          <Empty slot="empty" />
        </el-table>
      </div>
    </div>
    <PageNum :apiData="apiData" @sizeChange="sizeChange" @getData="getData" />
    <el-dialog
      :title="dialogTitle"
      :visible.sync="dialogVisible"
      width="50%"
      top="2%"
      :center="true"
      :before-close="doClose"
    >
      <el-form label-width="120px" ref="Form" :model="Form">
        <el-form-item label="学员姓名" prop="studentname">
          <el-input
            v-model="Form.studentname"
            type="text"
            size="small"
            :disabled="disabled"
          />
        </el-form-item>
        <el-form-item label="身份证号" required>
          <el-input
            v-model="Form.idCard"
            type="text"
            size="small"
            :disabled="disabled"
          />
        </el-form-item>
        <el-form-item label="性别" required>
          <el-radio-group v-model="Form.sex" disabled>
            <el-radio label="1">男</el-radio>
            <el-radio label="2">女</el-radio>
          </el-radio-group>
        </el-form-item>
        <el-form-item label="联系电话" required class="Classitem">
          <el-input
            v-model="Form.mobile"
            type="text"
            size="small"
            :disabled="disabled"
          />
        </el-form-item>
        <el-form-item label="电子邮件" required>
          <el-input
            v-model="Form.email"
            type="text"
            size="small"
            :disabled="disabled"
          />
        </el-form-item>
        <el-form-item label="所属公司" required>
          <el-input
            v-model="Form.company"
            type="text"
            size="small"
            :disabled="disabled"
          />
        </el-form-item>
        <el-form-item label="文化水平" required>
          <el-select
            v-model="Form.educationLevel"
            placeholder="请选择文化水平"
            size="small"
            :disabled="disabled"
          >
            <el-option
              v-for="item in educationList"
              :key="item.value"
              :label="item.label"
              :value="item.value"
            ></el-option>
          </el-select>
        </el-form-item>
      </el-form>
    </el-dialog>
    <el-dialog
      title="下载培训档案"
      :visible.sync="dialogDownload"
      width="50%"
      top="2%"
      center
      :before-close="closeDownloadTrainDocument"
    >
      <div id="pdf-content" style="height: 600px"></div>
    </el-dialog>
    <el-dialog
      title="下载学时证明"
      :visible.sync="dialogCert"
      width="50%"
      top="2%"
      center
      :before-close="closeDownloadCert"
    >
      <div id="pdf-cert" style="height: 600px"></div>
    </el-dialog>
  </div>
</template>
<script>
import Empty from "@/components/Empty.vue";
import PageNum from "@/components/PageNum.vue";
import List from "@/mixins/List";
import { mapGetters } from "vuex";
import pdf from "pdfobject";
export default {
  name: "shanxiStudentList",
  components: {
    Empty,
    PageNum,
  },
  props: ["length"],
  mixins: [List],
  data() {
    return {
      dialogTitle: "",
      disabled: true,
      dialogVisible: false,
      userJson: {},
      userName: "", //姓名
      idcard: "", //身份证号
      mobile: "", //手机号
      completeState: "", //学习状态 10 20 30 40
      complete: [],
      sourseState: "",
      sourseList: [],
      educationList: [],
      companyList: [],
      shanxiCouponCode: "", //电子券编号
      Form: {
        studentname: "",
        idCard: "",
        sex: "",
        mobile: "",
        email: "",
        company: "",
        compId: "",
        educationLevel: "",
      },
      activeName: "first",
      projectUserCourseId: "",
      dialogDownload: false,
      dialogCert: false,
      projectName: "",
      courseCount: "",
      projectId: "", //班级id
      ratioEnd: undefined, //比率结束
      ratioStart: undefined, //比率开始
      terminationEndTime: "", //用户终止时间-结束
      terminationStartTime: "", //用户终止时间-开始
      shanxiUserEnd: "", //用户时间
      ticketEndTime: "", //领券时间--结束
      ticketStartTime: "", //领券时间--开始
      shanxiUserStart: "", //
      shanxiUserPushState:'',
      pushStatus:[
        {
          value:true,
          label:'已推送'
        },
        {
          value:false,
          label:'未推送'
        }
      ]
    };
  },
  created() {
    this.getTableHeight();
    this.signEndDateLong = sessionStorage.getItem("signEndDateLong");
    const query = this.$route.query;
    if (query.active == "three") {
      this.openOnce();
    }
  },
  methods: {
      // 每天弹一次  别删
    openOnce() {
      //每天首次进入的时候弹提示--------- 开始
      let userId = JSON.parse(sessionStorage.getItem("userJson")).userId;
      let user = JSON.parse(localStorage.getItem(userId)) || {};
      //获取当前时间
      let date = new Date();
      let year = date.getFullYear();
      let month = date.getMonth() + 1;
      let day = date.getDate();
      if (month < 10) {
        month = "0" + month;
      }
      if (day < 10) {
        day = "0" + day;
      }
      let nowDate = year + "-" + month + "-" + day;
      // console.log(localStorage.getItem(userId));

      if (!localStorage.getItem(userId)) {
        console.log("1");
        this.$confirm(
          "根据有关部门业务开展需要，相关数据会涉及个人信息、照片等敏感信息，导出数据仅限于业务范围内使用，不得用于其他用途，如因个人原因导致数据泄露，由个人承担法律责任。",
          "提示",
          {
            showClose: false, //是否显示右上角关闭按钮
            showCancelButton: false, //是否显示取消按钮
            closeOnClickModal: false, //是否可通过点击遮罩关闭 MessageBox
            confirmButtonText: "同意并继续",
            confirmButtonClass: "bgc-bv",
          }
        )
          .then(() => {
            let userKey = {};
            userKey["StudentList"] = nowDate;
            localStorage.setItem(userId, JSON.stringify(userKey));
          })
          .catch(() => {});
      } else if (user.StudentList == undefined || user.StudentList < nowDate) {
        console.log("2");
        this.$confirm(
          "根据有关部门业务开展需要，相关数据会涉及个人信息、照片等敏感信息，导出数据仅限于业务范围内使用，不得用于其他用途，如因个人原因导致数据泄露，由个人承担法律责任。",
          "提示",
          {
            showClose: false, //是否显示右上角关闭按钮
            showCancelButton: false, //是否显示取消按钮
            closeOnClickModal: false, //是否可通过点击遮罩关闭 MessageBox
            confirmButtonText: "同意并继续",
            confirmButtonClass: "bgc-bv",
          }
        )
          .then(() => {
            let userKey = JSON.parse(localStorage.getItem(userId));
            userKey.StudentList = nowDate;
            localStorage.setItem(userId, JSON.stringify(userKey));
          })
          .catch(() => {});
      }
      //每天首次进入的时候弹提示-------- 结束
    },
    //学习时间 领券时间格式化
    shanxiUser(row, column) {
      var date = row[column.property];
      if (date === undefined) {
        return "";
      }
      return this.$moment(date).format("YYYY-MM-DD");
    },
    //单元测试总正确率格式化
    getratio(row) {
      if (row.ratio != 0 && row.ratio != undefined) {
        return row.ratio + "%";
      } else if (row.ratio == undefined) {
        return "";
      } else {
        return 0;
      }
    },
    init() {
      this.projectId = sessionStorage.getItem("projectId");
      this.projectName = sessionStorage.getItem("projectName");
      this.courseCount = sessionStorage.getItem("courseCount");
      this.getcompleteList();
      this.getData(-1);
    },
    // 获取学习状态&& 学历
    getcompleteList() {
      const studylist = this.$setDictionary("LEARNINGSTATE", "list");
      const educationList = this.$setDictionary("EDUCATION", "list");
      const sourseList = this.$setDictionary("SOURCEUSERSTATE", "list");
      const list = [];
      const eduList = [];
      const sourseLists = [];
      for (const key in studylist) {
        list.push({
          value: key,
          label: studylist[key],
        });
      }
      for (const key in educationList) {
        eduList.push({
          value: key,
          label: educationList[key],
        });
      }
      for (const key in sourseList) {
        sourseLists.push({
          value: key,
          label: sourseList[key],
        });
      }
      this.educationList = eduList;
      this.complete = list;
      this.sourseList = sourseLists;
    },
    //列表数据
    getData(pageNum) {
      const params = {
        pageSize: this.pageSize,
        projectId: this.projectId,
        ratioStart: this.ratioStart == 0 ? 0 :this.ratioStart,
        ratioEnd: this.ratioEnd  == 0 ? 0 :this.ratioEnd,
        shanxiUserPushState: this.shanxiUserPushState,
      };
      if (this.completeState) {
        params.completeState = this.completeState;
      }
      if (this.idcard) {
        params.idcard = this.idcard;
      }
      if (this.mobile) {
        params.mobile = this.mobile;
      }
      if (this.shanxiUserStart) {
        params.ticketStartTime = this.shanxiUserStart[0];
      }
      if (this.shanxiUserStart) {
        params.ticketEndTime = this.shanxiUserStart[1];
      }
      if (this.shanxiUserEnd[0]) {
        params.terminationStartTime = this.shanxiUserEnd[0];
      }
      if (this.shanxiUserEnd[1]) {
        params.terminationEndTime = this.shanxiUserEnd[1];
      }
      if (this.userName) {
        params.userName = this.userName;
      }
      if (this.shanxiCouponCode) {
        params.shanxiCouponCode = this.shanxiCouponCode;
      }
      this.doFetch({
        url: "/biz/projectUser/shanxi/listPage",
        params,
        pageNum,
      });
    },
    Learningrecords(row) {
      sessionStorage.setItem("row", JSON.stringify(row));
      this.$router.push({
        path: "/web/Learningrecords",
      });
    },
    doClose(done) {
      done();
    },
    lookstudent(projectUserCourseId) {
      this.$post("/biz/projectUser/queryCourseUser", { projectUserCourseId })
        .then((ret) => {
          let records = ret.data || {};
          this.Form.studentname = records.userName;
          this.Form.idCard = records.idcard;
          this.Form.sex = records.sex;
          this.Form.mobile = records.mobile;
          this.Form.email = records.email || "无";
          this.Form.company = records.compName || "无";
          this.Form.compId = records.compId || "";
          this.Form.educationLevel = records.educationLevel || "无";
          this.Form.userId = records.userId || "";
          this.dialogTitle = "查看学员信息";
          this.dialogVisible = true;
        })
        .catch((err) => {
          return;
        });
    },
    // 导出
    doExport() {
      this.$confirm(
        "导出数据仅限于业务范围内使用，不得用于其它用途，如因个人原因导致数据泄露，由个人承担法律责任。",
        "导出提示",
        {
          confirmButtonText: "导出",
          confirmButtonClass: "confirmButtonClass",
          cancelButtonText: "取消",
        }
      )
        .then(() => {
          const params = {
            pageNum: this.pageNum,
            pageSize: this.pageSize,
            projectId: this.projectId,
             ratioStart: this.ratioStart == 0 ? 0 :this.ratioStart,
        ratioEnd: this.ratioEnd  == 0 ? 0 :this.ratioEnd,
          };
          if (this.completeState) {
            params.completeState = this.completeState;
          }
          if (this.idcard) {
            params.idcard = this.idcard;
          }
          if (this.mobile) {
            params.mobile = this.mobile;
          }
          if (this.shanxiUserStart) {
            params.ticketStartTime = this.shanxiUserStart[0];
          }
          if (this.shanxiUserStart) {
            params.ticketEndTime = this.shanxiUserStart[1];
          }
          if (this.shanxiUserEnd[0]) {
            params.terminationStartTime = this.shanxiUserEnd[0];
          }
          if (this.shanxiUserEnd[1]) {
            params.terminationEndTime = this.shanxiUserEnd[1];
          }
          if (this.userName) {
            params.userName = this.userName;
          }
          if (this.shanxiCouponCode) {
            params.shanxiCouponCode = this.shanxiCouponCode;
          }
          this.$post("/biz/projectUser/shanxiProjectUserReport", params).then(
            (res) => {
              if (res.status == "0") {
                window.open(res.data);
              }
            }
          );
        })
        .catch(() => {});
    },
    downloadTrainDocument(projectId, userId) {
      let parmar = {
        projectId: projectId,
        userId: userId,
      };
      this.$post("/cert/downloadTrainDocument", parmar).then((res) => {
        if (res.status == 0) {
          this.dialogDownload = true;
          this.$nextTick(() => {
            pdf.embed(res.data, "#pdf-content");
          });
        } else {
          that.$message.warning({ message: res.message, duration: 1500 });
        }
      });
    },
    closeDownloadTrainDocument() {
      this.dialogDownload = false;
    },
    downloadTrainCert(projectId, userId) {
      let parma = {
        projectId: projectId,
        userId: userId,
      };
      this.$post("/cert/downloadCert", parma).then((res) => {
        if (res.status == 0) {
          this.dialogCert = true;
          this.$nextTick(() => {
            pdf.embed(res.data, "#pdf-cert");
          });
        } else {
          that.$message.warning({ message: res.message, duration: 1500 });
        }
      });
    },
    //推送学习记录
    pushLearningProgress(shanxiUserId) {
      this.$post("/biz/sou/shanxi/doPushUser", {shanxiUserId:shanxiUserId}).then((res) => {
        if (res.status == 0) {
          this.$message.success({ message: res.message, duration: 1500 });
          this.getData();
        } else {
          this.$message.warning({ message: res.message, duration: 1500 });
        }
      });
    },
    closeDownloadCert() {
      this.dialogCert = false;
    },
    getTableHeight(opDom = true, page = true) {
      let tHeight =
        window.innerHeight - (3 + 1.35 + 3.25 + 0.675 + 2 + 1.35 + 8) * 16;
      if (opDom) {
        tHeight -= 40 + 0.675 * 16 + 1;
      }
      if (page) {
        tHeight -= 32;
      }
      this.tableHeight = tHeight;
    },
  },
};
</script>
<style lang="less">
.upload-workers {
  height: 40px;
  .el-upload {
    height: 40px !important;
    border: none !important;
  }
}
</style>
<style lang="less" scoped>
.listWrap {
  .el-icon-arrow-down {
    font-size: 12px;
    &:before {
      content: "\e6df" !important;
    }
  }
  .el-tabs {
    flex: 1;
    display: flex;
    flex-direction: column;
    .el-tabs__content {
      flex: 1;
    }
  }
}
.Classitem {
  .el-form-item__content {
    display: flex;
  }
}
.el-button.is-disabled,
.el-button.is-disabled:focus,
.el-button.is-disabled:hover {
  background-color: transparent;
}

.confirmButtonClass {
  color: #fff !important;
  background-color: #5c6be8 !important;
  border-color: #5c6be8 !important;
}
.btnBox {
  margin-left: 1.5rem;
  margin-right: 1.5rem;
}
.exportdialog {
  .el-dialog__title {
    font-size: 16px;
  }
  .export-box {
    width: 100%;
    margin-bottom: 20px;
    h1 {
      padding: 0 5px 10px;
      display: flex;
      align-items: flex-end;
      font-size: 40px;
      border-bottom: 1px dashed #ccc;
      span {
        margin-left: 20px;
        font-size: 14px;
      }
    }
    > div {
      padding-left: 30px;
    }
  }
}

.itemLabel {
  display: inline-block !important;
  white-space: nowrap !important;
  min-width: 6rem;
}
.el-input-number.is-without-controls .el-input__inner {
  padding-left: 0 !important;
  padding-right: 0 !important;
}
@media screen and (max-width: 1440px) {
  .itemLabel_study {
    width: 19.1rem;
  }
  .phoneitemLabel {
    min-width: 5.2rem;
  }
}
</style>
