<template>
  <!-- <div > -->
  <div class="framePage-scroll">
    <div class="ovy-a">
      <h3 style="padding-bottom: 20px">基本信息</h3>
      <el-form ref="Form" :model="Form" :rules="rules" label-width="120px" class>
        <el-form-item label="班级名称：" prop="projectName" class="form-item">
          <el-input v-model="Form.projectName" placeholder="请输入班级名称" :disabled="disabled" size="small" maxlength="50"
            show-word-limit />
        </el-form-item>
        <el-form-item label="行政区划：" prop="areaId" class="form-item">
          <el-cascader v-model="Form.areaId" :options="areatreeList" :props="propsarea" :disabled="disabled" clearable
            filterable @change="areaChange" size="small"></el-cascader>
        </el-form-item>
        <!-- 只有上海才显示街道 - 这三个区必填，其余非必填 -->
        <el-form-item label="所属街道：" :prop="['310114', '310112', '310118'].includes(Form.areaId) ? 'street' : ''"
          class="form-item" v-if="zF.getString2(Form.areaId, 2) == '31'">
          <el-select size="small" v-model="Form.street" remote type="text" :remote-method="getStreetData" filterable
            clearable placeholder="请选择街道">
            <el-option v-for="item in streetData" :key="item.streetName" :label="item.streetName"
              :value="item.streetName"></el-option>
          </el-select>
        </el-form-item>
        <el-form-item label="有效日期：" prop="Effectivedate" class="form-item">
          <el-date-picker size="small" :disabled="disabledcc" v-model="Form.Effectivedate" type="daterange"
            range-separator="至" start-placeholder="开始日期" end-placeholder="结束日期" value-format="timestamp"></el-date-picker>
        </el-form-item>
        <el-form-item label="报名截止时间：" prop="deadline" class="form-item">
          <el-date-picker size="small" :disabled="signEndDateDisabled" v-model="Form.deadline" type="date"
            format="yyyy-MM-dd" value-format="timestamp" placeholder="选择日期"></el-date-picker>
        </el-form-item>
        <el-form-item label="负责人：" prop="projectUser" class="form-item">
          <el-input v-model="Form.projectUser" placeholder="请输入负责人" :disabled="disabled" size="small" />
        </el-form-item>
        <el-form-item label="负责人电话：" prop="projectUserPhone" class="form-item">
          <el-input v-model="Form.projectUserPhone" placeholder="请输入负责人电话" maxlength="12" :disabled="disabled"
            size="small" />
        </el-form-item>
        <el-form-item label="培训类型：" prop="Trainingtype" class="form-item">
          <el-input v-if="auditState == '30' || auditState == '20' || projectSource == '20'
            " v-model="Form.Trainingtype" type="text" size="small" :disabled="true" />
          <tree v-else ref="tree" @eventBtn="childBack" :ruleForm="Form" size="small" @clearParams="clearParams" modal
            type typeStu :threePostsLimit="'1'" />
        </el-form-item>
        <el-form-item label="培训人数" prop="projectPeople" class="form-item" v-if="selectType != '0'" required>
          <el-input-number v-model="Form.projectPeople" @change="handleChange" :min="1" style="width: 100%"
            :disabled="disabled" size="small"></el-input-number>
        </el-form-item>
        <el-form-item label="培训地点" prop="projectAddress" class="form-item" v-if="selectType != '0'">
          <el-input type="text" v-model="Form.projectAddress" :disabled="disabled" size="small"></el-input>
        </el-form-item>
        <el-form-item label="期次" prop="projectPc" class="form-item" v-if="selectType != '0'">
          <el-input type="text" v-model="Form.projectPc" :disabled="disabled" size="small"></el-input>
        </el-form-item>
        <el-form-item label="考试机构" prop="projectPaperComp" class="form-item" v-if="selectType != '0'">
          <el-input type="text" v-model="Form.projectPaperComp" :disabled="disabled" size="small"></el-input>
        </el-form-item>
        <el-form-item label="考试时间" prop="projectPaperDate" class="form-item" v-if="selectType != '0'">
          <el-date-picker v-model="Form.projectPaperDate" :disabled="disabled" type="date" format="yyyy-MM-dd"
            value-format="timestamp" placeholder="选择日期" size="small"></el-date-picker>
        </el-form-item>
        <el-form-item label="人群类型" prop="projectCrowd" class="form-item" v-if="selectType == '4'">
          <el-select v-model="Form.projectCrowd" placeholder="请选择" :disabled="disabled" size="small">
            <el-option v-for="item in CrowdtypeList" :key="item.value" :label="item.label"
              :value="item.value"></el-option>
          </el-select>
        </el-form-item>
        <el-form-item label="是否班级补贴：" prop="isSubsidy" class="form-item">
          <el-select v-model="Form.isSubsidy" placeholder="请选择" size="small" filterable clearable :disabled="disabled">
            <el-option v-for="item in SubsidyList" :key="item.value" :label="item.label" :value="item.value"></el-option>
          </el-select>
        </el-form-item>
        <el-form-item label="项目编号：" prop="projectRecordno" class="form-item" :rules="[
          {
            required: zF.getString2(Form.areaId, 2) == '31'&&params.trainFormId == '5' ? true : false,
            message: '请输入项目编号',
            trigger: 'blur',
          },
        ]">
          <el-input v-model="Form.projectRecordno" placeholder="请输入项目编号" :disabled="disabled" size="small"
            maxlength="50" />
        </el-form-item>
        <el-form-item label="备案课时数：" prop="recordClasshours" class="form-item" :rules="[
          {
            required:
              ['3101', '3100'].includes(Form.areaId.slice(0, 4)) ||
                (['130', '131', '139'].includes(Form.areaId.slice(0, 3)) &&
                  disableStu)
                ? true
                : false,
            message: '请输入备案课时数',
            trigger: 'blur',
          },
        ]">
          <!-- <el-input
            v-model="Form.recordClasshours"
            placeholder="请输入备案课时数"
            @keyup.native="
              Form.recordClasshours = zF.oninput2(Form.recordClasshours, 2)
            "
            :disabled="disabled"
            size="small"
          /> -->
          <el-input-number v-model="Form.recordClasshours" :disabled="disabled" :min="0" :max="999" style="width: 100%"
            size="small"></el-input-number>
        </el-form-item>
        <el-form-item label="培训方式" prop="trainMethod" class="form-item" v-if="zF.getString2(Form.areaId, 2) == '41'">
          <el-select v-model="Form.trainMethod" placeholder="请选择" :disabled="disabled" size="small">
            <el-option v-for="item in TrainingMethodsList" :key="item.value" :label="item.label"
              :value="item.value"></el-option>
          </el-select>
        </el-form-item>
        <el-form-item label="申请表图片" prop="thumbnail" class="form-item" v-if="selectType != '0'">
          <el-upload class="upload-btndrag" :action="actionUrl" :on-error="handleError" :on-success="handleSuccess"
            :on-change="uploadChange1" :show-file-list="false" :auto-upload="false" drag :disabled="disabled">
            <i class="el-icon-upload"></i>
            <div class="el-upload__text">
              将文件拖到此处，或<em>点击上传</em>
            </div>
          </el-upload>
          <div v-if="Form.uploadfileList.length" style="margin-top: 10px; margin-left: 10px">
            当前选择文件：
            <div style="display: flex; flex-direction: column">
              <span style="color: #409eff" v-for="(item, index) in Form.uploadfileList" :key="index">
                {{ item.fileName }}
                <a @click="lookExl(item)" style="color: #409eff; margin: 0 15px">预览</a>
                <a v-show="auditState != '30' &&
                  auditState != '20' &&
                  auditState != '25'
                  " @click="reomveExlUpload2(index)" style="color: red; margin: 0 15px">删除</a>
              </span>
            </div>
          </div>
          <div v-else>
            <p style="margin-top: 10px; margin-left: 10px">未选择文件</p>
          </div>
        </el-form-item>
        <el-form-item label="上传附件" prop="fileName" class="form-item" v-if="Form.areaId.indexOf('62') != -1">
          <el-button class="bgc-bv" size="mini" @click="uploadAttachments">上传附件</el-button>

          <div style="display: flex; flex-direction: column">
            <span style="color: #409eff" v-for="(item, index) in Form.chatFileKeys" :key="index">
              {{ item.fileStyleValue }}
              <a @click="downloadFile(item)" v-if="item.downloadUrl" style="color: #5bb5ff; margin: 0 15px">下载</a>

              <a @click="reomveExl(index)" style="color: red; margin: 0 15px">删除</a>
            </span>
          </div>
          <!-- <div style="display: flex; align-items: center">
            <el-upload
              class="upload-demo upload-btn"
              :action="actionUrl"
              :on-error="handleError"
              :on-success="handleSuccess"
              :on-change="uploadChange"
              multiple
              :file-list="uploadFileList"
              :show-file-list="false"
              :auto-upload="false"
            >
              <el-button class="bgc-bv" size="mini">上传附件</el-button>
            </el-upload>
            <span style="color: #f46173"
              >支持上传word、excel、图片、pdf等，文件大小不超过1M。</span
            >
          </div>
          <div style="display: flex; flex-direction: column">
            <span
              style="color: #409eff"
              v-for="(item, index) in Form.chatFileKeys"
              :key="index"
            >
              {{ item.fileName }}
              <a @click="downloadFile(item)" v-if="item.downloadUrl" style="color: #5bb5ff;margin:0 15px">下载</a>
              <a @click="reomveExl(index)" style="color: red; margin: 0 15px"
                >删除</a
              >
            </span>
          </div> -->
        </el-form-item>
      </el-form>
      <h3 style="padding-bottom: 20px">基本设置</h3>
      <div style="display: flex; flex-direction: column">
        <div>
          <span style="padding: 0 15px; width: 13rem; text-align: right">允许学员查看学时证明</span>
          <el-radio-group v-model="Form.projectClassHourCertificate">
            <el-radio :label="true">是</el-radio>
            <el-radio :label="false">否</el-radio>
          </el-radio-group>
        </div>
        <div>
          <span style="padding: 15px 15px; width: 13rem; text-align: right">允许学员查看培训证书</span>
          <el-radio-group v-model="Form.studentCertPrint">
            <el-radio :label="true">是</el-radio>
            <el-radio :label="false">否</el-radio>
          </el-radio-group>
        </div>
        <!--上海区划才有-->
        <div v-if="Form.areaId && Form.areaId.substring(0, 3) == '310'">
          <span style="padding: 15px 15px; width: 13rem; text-align: right">课后练习开始考试后最短</span>
          <el-input-number v-model="Form.paraAnalysisLimitMinTime" size="small" :controls="false" :min="5" :step="1"
            :precision="0"
            :disabled="projectStudyTimeState == '20' || projectStudyTimeState == '30' || projectStudyTimeState == '50' || projectStudyTimeState == '60'"></el-input-number>
          分钟交卷
        </div>
        <!--上海区划才有-->
        <div v-if="Form.areaId && Form.areaId.substring(0, 3) == '310'">
          <span style="padding: 15px 15px; width: 13rem; text-align: right">结业考试开始考试后最短</span>
          <el-input-number v-model="Form.paraExamLimitMinTime" size="small" :controls="false" :min="5" :step="1"
            :precision="0"
            :disabled="projectStudyTimeState == '20' || projectStudyTimeState == '30' || projectStudyTimeState == '50' || projectStudyTimeState == '60'"></el-input-number>
          分钟交卷
        </div>
        <div v-show="params.trainFormId == '11'">
          <span style="padding: 15px 15px; width: 13rem; text-align: right">是否开启问卷调查</span>
          <el-radio-group v-model="Form.enableSurvey">
            <el-radio :label="true">是</el-radio>
            <el-radio :label="false">否</el-radio>
          </el-radio-group>
          <p style="padding-left: 211px">
            开启问卷调查后,学员学完课程后会被随机抽取进行调查评问卷的填写，结果会记录在《综合考评报告》档案中。
          </p>
          <el-form ref="Form1" :model="Form1" :rules="rules1" label-width="130px" v-show="Form.enableSurvey">
            <el-form-item label="抽查人数" prop="spotCheckNum" class="form-item">
              <el-input-number v-model="Form1.spotCheckNum" :controls="false" :min="0" :precision="0" />
            </el-form-item>
          </el-form>
        </div>
      </div>
      <div style="padding: 15px 0" v-show="Form.auditState == '40'">
        <h3 style="padding-bottom: 10px">审核事由</h3>
        <el-input type="textarea" disabled v-model="Form.auditReason"></el-input>
      </div>
      <div style="padding: 15px 0" v-show="Form.delay">
        <h3 style="padding-bottom: 10px">延期申请</h3>
        <el-table ref="multipleTable" :data="tableDelayData" size="small" tooltip-effect="dark" style="width: 100%"
          :header-cell-style="tableHeader" stripe>
          <el-table-column label="序号" align="center" type="index" fixed :index="indexMethod" />
          <el-table-column label="申请人" align="left" show-overflow-tooltip prop="fullName" fixed="left" />
          <el-table-column label="申请日期" align="left" show-overflow-tooltip prop="createTime" fixed="left">
            <template slot-scope="scope">
              {{ scope.row.createTime | moment }}
            </template>
          </el-table-column>
          <el-table-column label="原结束时间" align="left" show-overflow-tooltip prop="endDateOld">
            <template slot-scope="scope">
              {{ scope.row.endDateOld | momentDate }}
            </template>
          </el-table-column>
          <el-table-column label="申请结束时间" align="left" show-overflow-tooltip prop="endDateNew">
            <template slot-scope="scope">
              {{ scope.row.endDateNew | momentDate }}
            </template>
          </el-table-column>
          <el-table-column label="审核结果" align="left" show-overflow-tooltip prop="delayAuditType">
            <template slot-scope="scope">
              {{ $setDictionary("DELAY_AUDIT_TYPE", scope.row.delayAuditType) }}
            </template>
          </el-table-column>
          <el-table-column label="备注" align="left" show-overflow-tooltip prop="delayAuditRemark" />
          <el-table-column label="操作" align="left" fixed="right">
            <template slot-scope="scope">
              <div>
                <el-button type="text" size="mini" style="padding: 0 5px"
                  @click="lookFile(scope.row.projectDelayId)">查看附件</el-button>
              </div>
            </template>
          </el-table-column>
        </el-table>
      </div>

      <div class="bottom-btn">
        <el-button @click="cancle" class="bgc-bv">取消</el-button>
        <el-button @click="seveData('Form')" class="bgc-bv">保存</el-button>
      </div>
    </div>
    <!-- 延期申请附件查看 -->
    <el-dialog title="查看附件" :visible.sync="filedialogVisible" width="30%">
      <el-table ref="multipleTable" :data="fileList" size="small" tooltip-effect="dark" style="width: 100%"
        :header-cell-style="tableHeader" stripe>
        <el-table-column label="文件名称" align="center" show-overflow-tooltip prop="delayFileName" />
        <el-table-column label="操作" align="center">
          <template slot-scope="scope">
            <div>
              <el-button type="text" size="mini" style="padding: 0 5px" @click="jumpLook(scope.row)">查看附件</el-button>
            </div>
          </template>
        </el-table-column>
      </el-table>
    </el-dialog>
    <el-dialog title="预览" :visible.sync="previewLoding1" @close="uploadLodClose" width="50%" top="2%" center>
      <div class="ovy-a">
        <div id="pdf-cert1" style="height: 600px" v-if="fileType == 'pdf' || fileType == 'PDF'"></div>
        <div v-else>
          <img :src="ImgSrc" alt="" width="100%" height="100%" />
        </div>
      </div>
    </el-dialog>
    <el-dialog title="上传文件" :visible.sync="uploadFileDialogVisible" width="30%" top="5%" :center="true"
      :before-close="closeDocsDialog">
      <div>
        <div class="ovy-a">
          <p style="color: #f46173">
            注:支持上传word、excel、图片、pdf、压缩包等，单个文件大小不超过5M。
          </p>
          <el-table ref="multipleTable" :data="tableDataFile" :height="tableHeight" size="small" tooltip-effect="dark"
            style="width: 100%" :header-cell-style="tableHeader" stripe>
            <el-table-column label="文件类型" align="center">
              <temaplate slot-scope="scope">
                <el-select v-model="scope.row.fileStyle" clearable placeholder="请选择" size="small"
                  @change="FileStyleChange">
                  <el-option v-for="item in ProjectFileStyleList" :key="item.value" :label="item.label"
                    :value="item.value"></el-option>
                </el-select>
              </temaplate>
            </el-table-column>
            <el-table-column label="上传文件" align="left" show-overflow-tooltip prop="projectCode">
              <template slot-scope="scope">
                <div style="display: flex">
                  <el-upload class="upload-demo upload-btn" :action="actionUrl" :on-error="handleError"
                    :on-success="handleSuccess" :on-change="uploadChange" multiple :file-list="uploadFileList"
                    :show-file-list="false" :auto-upload="false">
                    <el-button size="mini" type="text">上传文件</el-button>
                  </el-upload>
                  <el-button type="text" size="mini" :disabled="!Files.allow" style="padding: 0 5px"
                    @click="downloadFileTemplate(scope.$index, Files)">下载模版</el-button>
                </div>
              </template>
            </el-table-column>
          </el-table>
        </div>
      </div>
    </el-dialog>
  </div>
</template>

<script>
import UploadPic from "@/mixins/UploadPic";
import DatePicker from "@/mixins/DatePicker";
import tree from "@/components/treePopup";
import pdf from "pdfobject";
import { mapGetters } from "vuex";
import List from "@/mixins/List";
export default {
  name: "TestPaper",
  components: {
    tree,
  },
  mixins: [UploadPic, DatePicker, List],
  data() {
    var proTypeFrom = (rule, value, callback) => {
      var state = this.selectType;
      if (state == 2) {
        if (value == "") {
          callback(new Error("请选择人群类型"));
        } else {
          callback();
        }
      } else {
        callback();
      }
    };
    var projectUserPhone = (rule, value, callback) => {
      if (!value) {
        callback(new Error("请输入负责人电话"));
      } else if (
        value &&
        !/^1[3456789]\d{9}$/.test(value) &&
        !/^([0-9]{3,4}-)?[0-9]{7,8}$/.test(value)
      ) {
        callback(new Error("请输入正确的电话号码"));
      } else {
        callback();
      }
    };

    return {
      disabled: false,
      disabledcc: false,
      paperId: false,
      editJson: {},
      areatreeList: [],
      datatreeList: [], //培训类型
      CrowdtypeList: [],
      props: {
        value: "id",
        label: "label",
      },
      streetData: [],
      propsarea: {
        value: "value",
        label: "label",
        emitPath: false,
        checkStrictly: true,
      },
      selectType: "0",
      Form: {
        projectId: "",
        projectName: "",
        areaId: "",
        street: "",
        Effectivedate: [],
        deadline: "",
        projectUser: "",
        projectUserPhone: "",
        projectPeople: "",
        projectAddress: "",
        projectPc: "",
        projectPaperComp: "",
        Examinationtime: "",
        proType: "",
        thumbnail: "",
        fileKey: "",
        Trainingtype: "",
        projectClassHourCertificate: true,
        studentCertPrint: false,
        paraExamLimitMinTime: 5,
        paraAnalysisLimitMinTime: 5,
        enableSurvey: false,
        auditState: "",
        auditReason: "",
        spotCheckNum: "",
        delay: false,
        chatFileKeys: [],
        trainMethod: "",
        uploadfileList: [],
        projectRecordno: "", //备案号
        recordClasshours: undefined, //备案课时数
        isSubsidy: "",
      },
      TrainingMethodsList: [],
      uploadFileList: [],
      ruleForm: {
        Trainingtype: "",
      },
      Form1: {
        spotCheckNum: "",
      },
      params: {},
      rules: {
        projectName: [
          { required: true, message: "请输入班级名称", trigger: "blur" },
        ],
        areaId: [
          { required: true, message: "请选择行政区划", trigger: "change" },
        ],
        street: [
          { required: true, message: "请选择所属街道", trigger: "change" },
        ],
        Effectivedate: [
          { required: true, message: "请选择有效时间", trigger: "change" },
        ],
        deadline: [
          { required: true, message: "请选择报名截止时间", trigger: "blur" },
        ],
        projectUser: [
          { required: true, message: "请输入负责人", trigger: "blur" },
        ],
        projectUserPhone: [
          { required: true, validator: projectUserPhone, trigger: "blur" },
        ],
        projectCrowd: [
          { required: true, message: "请选择人群类型", trigger: "change" },
        ],
        Trainingtype: [
          {
            required: true,
            message: "请选择培训类型",
            trigger: ["change", "blur"],
          },
        ],
        trainMethod: [
          { required: true, message: "请选择培训方式", trigger: "change" },
        ],
      },
      rules1: {
        spotCheckNum: [
          { required: true, message: "请输入抽查人数", trigger: "blur" },
        ],
      },
      projectSource: "",
      projectStudyTimeState: "",
      fileList: [],
      filedialogVisible: false,
      fileType: "",
      previewLoding1: false,
      ImgSrc: "",
      signEndDateDisabled: false,
      //上传文件
      uploadFileDialogVisible: false,
      tableDataFile: [],
      ProjectFileStyleList: [],
      Files: {},
      disableStu: false,
      trainTypeIds: [],
      SubsidyList: [
        {
          value: true,
          label: "是",
        },
        {
          value: false,
          label: "否",
        },
      ],
    };
  },
  created() {
    this.editJson = this.$route.query;
    this.projectId = sessionStorage.getItem("projectId");
    this.auditState = sessionStorage.getItem("auditState");
    this.projectSource = sessionStorage.getItem("projectSource");
    this.projectStudyTimeState = sessionStorage.getItem("projectStudyTimeState");

    if (this.projectSource == "20") {
      this.disabled = true;
      this.disabledcc = true;
    }
    this.getCrowdtypeList();
    this.getareatree();
    if (
      this.auditState == "30" ||
      this.auditState == "20" ||
      this.auditState == "25"
    ) {
      this.disabled = true;
      this.disabledcc = true;
    }
    this.loadData(this.projectId);
    this.getDelayList(this.projectId);
    this.getTrainTypeList2();
  },
  computed: {
    ...mapGetters({
      userJson: "getUser",
    }),
  },
  methods: {
    childBack(params) {
      this.params = { ...params };
      this.selectType = this.params.trainFormIdPath;
      if (this.trainTypeIds.indexOf(this.params.trainFormId) != -1) {
        this.disableStu = true;
      } else {
        this.disableStu = false;
      }
    },
    clearParams() {
      this.params = {};
    },
    getCrowdtypeList() {
      const CrowdtypeList = this.$setDictionary("CROWDTYPE", "list");
      const ProjectFileStyle = this.$setDictionary(
        "PROJECT_FILE_STYLE",
        "list"
      );
      const ProjectTrainMethod = this.$setDictionary(
        "PROJECT_TRAIN_METHOD",
        "list"
      );
      const list = [],
        ProjectFileStyleList = [],
        ProjectTrainMethodList = [];
      for (const key in CrowdtypeList) {
        list.push({
          value: key,
          label: CrowdtypeList[key],
        });
      }
      for (const key in ProjectFileStyle) {
        ProjectFileStyleList.push({
          value: key,
          label: ProjectFileStyle[key],
        });
      }
      for (const key in ProjectTrainMethod) {
        ProjectTrainMethodList.push({
          value: key,
          label: ProjectTrainMethod[key],
        });
      }
      this.CrowdtypeList = list;
      this.ProjectFileStyleList = ProjectFileStyleList;
      this.TrainingMethodsList = ProjectTrainMethodList;
    },
    loadData(projectId) {
      this.$post("/biz/project/query", { projectId })
        .then((ret) => {
          let data = ret.data;
          let res = JSON.stringify(data);
          res = res.replace(/areaId/g, "value");
          res = res.replace(/areaName/g, "label");
          res = res.replace(/trainTypeId/g, "id");
          res = JSON.parse(res);
          this.id = res.id;
          this.selectType = res.idPath.substr(0, 1);

          this.Form = {
            projectId: res.projectId,
            projectName: res.projectName,
            Effectivedate: [res.startDateLong, res.endDateLong],
            areaId: res.value,
            street: res.street || "",
            deadline: res.signEndDateLong,
            projectUser: res.projectUser,
            projectUserPhone: res.projectUserPhone,
            projectPaperDate: res.projectPaperDateLong,
            projectPaperComp: res.projectPaperComp,
            projectPc: res.projectPc,
            projectAddress: res.projectAddress,
            projectPeople: res.projectPeople,
            thumbnail: res.applicationFormUrl,
            projectCrowd: res.projectCrowd,
            fileKey: res.applicationForm,
            projectClassHourCertificate: res.projectClassHourCertificate,
            enableSurvey: res.enableSurvey,
            auditState: res.auditState,
            auditReason: res.auditReason,
            projectSource: res.projectSource || "",
            trainFormId: res.id,
            trainTypeNamePath: res.trainTypeNamePath,
            spotCheckNum: res.spotCheckNum,
            delay: res.delay,
            chatFileKeys: res.eduProjectFileList || [],
            trainMethod: res.trainMethod,
            studentCertPrint: res.studentCertPrint || false,
            paraExamLimitMinTime: res.paraExamLimitMinTime || undefined,
            paraAnalysisLimitMinTime: res.paraAnalysisLimitMinTime || undefined,
            uploadfileList: res.list || [],
            projectRecordno: res.projectRecordno, //备案号
            recordClasshours: res.recordClasshours, //备案课时数
            isSubsidy: res.isSubsidy, //班级补贴
          };
          this.Form1.spotCheckNum = res.spotCheckNum;
          let name1 = "";
          let name2 = "";
          let name3 = "";
          let name4 = "";
          let name5 = "";
          if (res.codeBo.trainTypeNamePath) {
            name1 = res.codeBo.trainTypeNamePath;
            this.params.trainFormId = res.codeBo.id;
            this.Form.trainFormId = res.codeBo.id;
          }
          if (res.codeBo.postName) {
            name2 = "/" + res.codeBo.postName;
            this.params.postFormId = res.codeBo.postId;
          }
          if (res.codeBo.industryNamePath) {
            name3 = "/" + res.codeBo.industryNamePath;
            this.params.industryFormId = res.codeBo.industryId;
          }
          if (res.codeBo.occupationNamePath) {
            name4 = "/" + res.codeBo.occupationNamePath;
            this.params.occFormId = res.codeBo.occupationId;
          }
          if (res.codeBo.trainLevelName) {
            name5 = "/" + res.codeBo.trainLevelName;
            this.params.levelFormId = res.codeBo.trainLevelId;
          }
          let name = name1 + name2 + name3 + name4 + name5;
          name = name.replace(/,/g, "-");
          this.Form.Trainingtype = name;
          var timestamp = new Date().getTime();
          if (Number(res.endDateLong) > Number(timestamp)) {
            this.signEndDateDisabled = false;
          } else {
            this.signEndDateDisabled = true;
          }
          if (this.trainTypeIds.indexOf(this.params.trainFormId) != -1) {
            this.disableStu = true;
          } else {
            this.disableStu = false;
          }
        })
        .catch((err) => {
          return;
        });
    },
    handleAvatarSuccess2(res) {
      let extension = res.name.substring(res.name.lastIndexOf(".") + 1);
      let formData = new FormData();
      formData.append("folder ", "COURSE_WARE");
      formData.append("file ", res.raw);
      formData.append("fileType ", extension);
      const isLt2M = res.size / 1024 / 1024 < 1;
      if (!isLt2M) {
        this.$message.error("上传图片大小不能超过 1MB!");
      } else if (
        ["image/png", "image/gif", "image/jpg", "image/jpeg"].indexOf(
          res.raw.type
        ) === -1
      ) {
        this.$message.error("请上传正确的图片格式");
        return false;
      } else {
        this.$Postformat("/sys/upload", formData)
          .then((result) => {
            this.Form.thumbnail = result.data.fileURL;
            this.Form.fileKey = result.data.fileKey;
          })
          .catch(() => {
            setTimeout(() => {
              this.$message({
                type: "warning",
                message: "上传失败",
              });
            }, 300);
          });
      }
    },
    getareatree() {
      this.$post("/sys/area/tree").then((ret) => {
        this.areatreeList = ret.data;
      });
    },
    seveData(ruleForm) {
      const el = this.$refs.Form;
      const el1 = this.$refs.Form1;
      el.validate((valid) => {
        if (valid) {
          // 待审核30; 审核通过20; 25前后台都没有这个字段，别删(不敢动)
          if (
            this.auditState == "30" ||
            this.auditState == "20" ||
            this.auditState == "25"
          ) {
            this.preservation_1();
            // 否则走正常 - 下面的判断我也不知道啥意思!!!
          } else {
            if (this.Form.enableSurvey && this.params.trainFormId == "11") {
              el1.validate((valid1) => {
                if (valid1) {
                  this.doAjax();
                }
              });
            } else if (
              !this.Form.enableSurvey &&
              this.params.trainFormId == "11"
            ) {
              this.doAjax();
            } else if (
              this.Form.areaId.indexOf("130") != -1 &&
              this.params.trainFormId == "5"
            ) {
              if (this.Form.uploadfileList.length < 1) {
                this.$confirm("开班申请表未上传，是否上传？", "提示", {
                  confirmButtonText: "是",
                  cancelButtonText: "否",
                  showClose: false,
                  closeOnClickModal: false,
                  type: "warning",
                })
                  .then(() => { })
                  .catch(() => {
                    this.doAjax();
                  });
              } else {
                this.doAjax();
              }
            } else {
              this.doAjax();
            }
          }
        }
      });
    },
    // 上传图片
    uploadChange(file, fileList) {
      this.uploadFileList = fileList;
      let size = file.size / 1024 / 1024;
      let extension = file.name.substring(file.name.lastIndexOf(".") + 1);
      if (size > 5) {
        this.$message.error("文件大小不能超过5M");
        this.handleError("上传失败", file, fileList);
        return;
      }
      let formData = new FormData();
      formData.append("folder ", "QUESTION");
      formData.append("file ", file.raw);
      formData.append("fileType ", extension);
      let fileStyle = "";
      let fileStyleName = "";
      this.tableDataFile.forEach((element) => {
        let obj = {};
        obj = this.ProjectFileStyleList.find((item) => {
          return item.value === element.fileStyle;
        });
        fileStyle = element.fileStyle;
        fileStyleName = obj.label;
      });
      this.$Postformat("/sys/upload", formData)
        .then((result) => {
          this.Form.chatFileKeys.push({
            fileKey: result.data.fileKey,
            fileName: file.name,
            fileType: extension,
            fileStyle,
            fileStyleValue: fileStyleName,
          });
        })
        .catch((err) => {
          setTimeout(() => {
            this.$message({
              type: "warning",
              message: "上传失败",
            });
          }, 300);
        });
      this.$forceUpdate();
    },
    uploadAttachments() {
      this.uploadFileDialogVisible = true;
      this.tableDataFile = [{ fileStyle: "" }];
    },
    //文件类型change事假
    FileStyleChange(val) {
      this.$post("/biz/project/template", { fileStyle: val }).then((ret) => {
        this.Files = ret.data;
      });
    },
    downloadFileTemplate(index, row) {
      window.open(row.downloadUrl);
    },
    handleError(err, file, fileList) {
      let index = fileList.findIndex((el) => el.name == file.name);
      this.uploadFileList.splice(index, 1);
    },
    // 下载
    downloadFile(item) {
      const link = document.createElement("a");
      link.href = item.downloadUrl;
      document.body.appendChild(link);
      link.click();
      document.body.removeChild(link);
    },
    // 删除
    reomveExl(index) {
      this.Form.chatFileKeys.splice(index, 1);
      this.uploadFileList.splice(index, 1);
      this.$forceUpdate();
    },
    // 街道
    getStreetData(val) {
      let cData = {
        areaId: this.Form.areaId
      }
      if (val) {
        cData.streetName = val;
      }
      this.$post("/sys/area/street", cData)
        .then((res) => {
          this.streetData = res.data;
        })
        .catch((err) => {
          console.log(err)
        });
    },
    // 保存
    doAjax() {
      let parmar = {
        projectName: this.Form.projectName,
        areaId: this.Form.areaId,
        startDate: this.Form.Effectivedate[0],
        endDate: this.Form.Effectivedate[1],
        projectUser: this.Form.projectUser,
        projectUserPhone: this.Form.projectUserPhone,
        projectPeople: this.Form.projectPeople,
        projectClassHourCertificate:
          this.Form.projectClassHourCertificate == true ? true : false,
        trainMethod: this.Form.trainMethod,
        projectRecordno: this.Form.projectRecordno,
        recordClasshours: this.Form.recordClasshours,
        studentCertPrint: this.Form.studentCertPrint,
        isSubsidy: this.Form.isSubsidy,
      };

      if (this.Form.areaId.substring(0, 3) == '310' && !this.Form.paraExamLimitMinTime) {
        this.$message.error('请输入结业考试开始考试后最短交卷时间！')
        return
      } else if (this.Form.areaId.substring(0, 3) == '310' && this.Form.paraExamLimitMinTime) {
        parmar.paraExamLimitMinTime = this.Form.paraExamLimitMinTime;
      }
      if (this.Form.areaId.substring(0, 3) == '310' && !this.Form.paraAnalysisLimitMinTime) {
        this.$message.error('请输入课后练习开始考试后最短交卷时间！')
        return
      } else if (this.Form.areaId.substring(0, 3) == '310' && this.Form.paraAnalysisLimitMinTime) {
        parmar.paraAnalysisLimitMinTime = this.Form.paraAnalysisLimitMinTime;
      }
      if (this.Form.street) {
        parmar.street = this.Form.street;
      }
      if (this.Form.deadline) {
        parmar.signEndDate = this.Form.deadline;
      }
      if (this.Form.projectAddress) {
        parmar.projectAddress = this.Form.projectAddress;
      }
      if (this.Form.projectCrowd) {
        parmar.projectCrowd = this.Form.projectCrowd;
      }
      if (this.Form.projectId) {
        parmar.projectId = this.Form.projectId;
      }
      if (this.Form.projectPc) {
        parmar.projectPc = this.Form.projectPc;
      }
      if (this.Form.projectPaperComp) {
        parmar.projectPaperComp = this.Form.projectPaperComp;
      }
      if (this.Form.projectPaperDate) {
        parmar.projectPaperDate = this.Form.projectPaperDate;
      }

      if (this.params.trainFormId) {
        parmar.trainTypeId = this.params.trainFormId;
      }
      if (this.params.levelFormId) {
        parmar.trainLevelId = this.params.levelFormId;
      }
      if (this.params.occFormId) {
        parmar.occupationId = this.params.occFormId;
      }
      if (this.params.postFormId) {
        parmar.postId = this.params.postFormId;
      }
      if (this.params.industryFormId) {
        parmar.industryId = this.params.industryFormId;
      }
      if (this.Form.projectSource) {
        parmar.projectSource = this.Form.projectSource;
      }
      if (this.params.trainFormId == "11") {
        parmar.enableSurvey = this.Form.enableSurvey;
        parmar.spotCheckNum = this.Form.enableSurvey
          ? this.Form1.spotCheckNum
          : 0;
        parmar.paperId = "1";
      }
      if (this.Form.chatFileKeys.length > 0) {
        parmar.eduProjectFileList = this.Form.chatFileKeys;
      }
      if (this.Form.uploadfileList.length > 0) {
        parmar.applicationForm = this.Form.uploadfileList;
      }
      this.$post("/biz/project/saveOrUpdate", parmar)
        .then((ret) => {
          if (ret.status == 0) {
            this.$message({
              message: ret.message,
              type: "success",
            });
            if (this.projectSource == "30") {
              this.$router.push({
                name: "sjz/jg/classList",
                params: {
                  refresh: true,
                  isAdd: false,
                },
              });
            } else {
              this.$router.push({
                name: "classListCtr",
                params: {
                  refresh: true,
                  isAdd: false,
                },
              });
            }
          }
        })
        .catch((err) => {
          return;
        });
    },
    cancle() {
      this.$router.push({
        path: "/web/classListCtr",
      });
    },
    // 保存 - 待审核30; 审核通过20
    preservation_1() {
      let data = {
        projectId: this.Form.projectId,
        signEndDate: this.Form.deadline,
        studentCertPrint: this.Form.studentCertPrint,
        projectClassHourCertificate: this.Form.projectClassHourCertificate,
        eduProjectFileList: this.Form.chatFileKeys || [],
      }
      if(this.params.trainFormId == '11'){
        data.enableSurvey = this.Form.enableSurvey;
        data.spotCheckNum = this.Form.enableSurvey
          ? this.Form1.spotCheckNum
          : 0;
      }
      this.$post("/biz/project/update/printCertificateState", data)
        .then((ret) => {
          if (ret.status == 0) {
            this.$message({
              message: ret.message,
              type: "success",
            });
            if (this.projectSource == "30") {
              this.$router.push({
                name: "sjz/jg/classList",
                params: {
                  refresh: true,
                  isAdd: false,
                },
              });
            } else {
              this.$router.push({
                name: "classListCtr",
                params: {
                  refresh: true,
                  isAdd: false,
                },
              });
            }
          }
        })
        .catch((err) => {
          return;
        });
    },
    //延期申请列表
    getDelayList(projectId) {
      this.$post("/edu/project/delay/page", { projectId }).then((ret) => {
        this.tableDelayData = ret.data || [];
      });
    },
    //查看附件
    lookFile(projectDelayId) {
      this.$post("/edu/project/delay/file", { projectDelayId }).then((ret) => {
        this.filedialogVisible = true;
        this.fileList = ret.data || [];
      });
    },
    jumpLook(item) {
      let extension = item.delayFileName.substring(
        item.delayFileName.lastIndexOf(".") + 1
      );
      this.fileType = extension;
      if (this.fileType == "pdf" || this.fileType == "PDF") {
        this.previewLoding1 = true;
        this.$nextTick(() => {
          pdf.embed(item.delayFileKey, "#pdf-cert1");
        });
      } else if (this.fileType == "png" || this.fileType == "jpg") {
        this.previewLoding1 = true;
        this.ImgSrc = item.delayFileKey;
      }
    },
    //行政区划change事件
    areaChange(val) {
      this.Form.street = '';
      if (this.zF.getString2(val, 2) == '31') {
        this.getStreetData();
      }
      if (val.indexOf("41") == -1) {
        this.Form.trainMethod = "10";
      } else {
        this.Form.trainMethod = "";
      }
    },
    reomveExlUpload(index) {
      this.uploadfileList.splice(index, 1);
      this.$forceUpdate();
    },
    uploadChange1(file) {
      this.fileName = file.name;
      let size = file.size / 1024 / 1024;
      let fileName = file.name.split(".");
      let extension = file.name.substring(file.name.lastIndexOf(".") + 1);
      const isXLSX = extension === "PDF";
      const isxlsx = extension === "pdf";
      const jpg = extension === "jpg";
      const png = extension === "png";
      // const isXLS = extension === "XLS";
      // const isxls = extension === "xls";
      if (!isXLSX && !isxlsx && !jpg && !png) {
        this.$message.error("只能上传后缀是.pdf或.PDF或.jpg或.png的文件");
        return;
      }
      if (size > 10) {
        this.$message.error("文件大小不能超过10M");
        return;
      }
      let formData = new FormData();
      formData.append("folder ", "USER");
      formData.append("file ", file.raw);
      formData.append("fileType ", extension);

      this.$Postformat("/sys/upload", formData)
        .then((result) => {
          this.Form.uploadfileList.push({
            fileName: file.name,
            fileKey: result.data.fileKey,
            fileType: extension,
            fileUrl: result.data.fileURL,
          });
        })
        .catch((err) => {
          return;
        });
    },
    reomveExlUpload2(index) {
      this.Form.uploadfileList.splice(index, 1);
      this.$forceUpdate();
    },
    lookExl(item) {
      this.previewLoding1 = true;
      this.fileType = item.fileType;
      if (item.fileType == "pdf" || item.fileType == "PDF") {
        setTimeout(() => {
          pdf.embed(item.fileUrl, "#pdf-cert1");
        }, 300);
      } else {
        this.ImgSrc = item.fileUrl;
      }
    },
    getTrainTypeList2() {
      this.$post("/sys/category/train-type/tree")
        .then((res) => {
          // this.trainTypeList = res.data;
          res.data.map((el) => {
            if (el.id == "4") {
              this.trainTypeIds.push(el.id);
              el.children.map((els) => {
                this.trainTypeIds.push(els.id);
                if (els.children) {
                  els.children.map((els1) => {
                    this.trainTypeIds.push(els1.id);
                  });
                }
              });
            }
          });
          if (this.trainTypeIds.indexOf(this.params.trainFormId) != -1) {
            this.disableStu = true;
          } else {
            this.disableStu = false;
          }
        })
        .catch(() => {
          return;
        });
    },
  },
};
</script>
<style lang="less">
.el-textarea {
  .el-textarea__inner {
    resize: none;
  }
}
</style>
<style lang="less" scoped>
.el-form {
  display: flex;
  flex-wrap: wrap;
  justify-content: space-between;

  .form-item {
    width: 45%;
  }
}

.bottom-btn {
  width: 100%;
  display: flex;
  align-items: center;
  justify-content: center;
  margin: 10px 0;
}

.framePage-scroll /deep/ .upload-btn {
  width: 5rem;
  height: 28px;

  .el-upload {
    width: 5rem;
    height: 28px !important;
    border: none !important;
  }
}
</style>
